import React, { useEffect, useState } from 'react';
import dateService from '../../../services/ticketing/date.service';
import { Alert, List, Space, Spin, Tag } from 'antd';
import { CalendarOutlined, LoadingOutlined } from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { useDispatch, useSelector } from 'react-redux';
import { set_final_price } from '../../../actions/date.action';
import numberHelper from '../../../helpers/functions/number.helper';
import moment from 'moment';

const OrderSummary = ({ticket, visit_date}) => {
    const dateRedux = useSelector(state => state.date);
    const currency = dateRedux?.park?.currency?.symbol;
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : true,
        user_prices : [],
        admin_fees : 0,
        total_price : 0,
    })

    const fetchCalculateChangeDatePrice = async () => {
        setState(state => ({...state, loading : true}));
        const changeDatePriceResponse = await dateService.calculateChangeDateUserPrice({ ticket_unique_no : ticket?.unique_no, new_date : visit_date });
        const { new_ticket_prices, process_fee, total_price } = changeDatePriceResponse  ?? {};

        setState(state => ({...state ,loading : false, user_prices : new_ticket_prices, admin_fees : process_fee, total_price : total_price,}));

        //set final price to redux
        dispatch(set_final_price(total_price));
    }

    useEffect(() => {
        fetchCalculateChangeDatePrice();
    }, [visit_date])
   
    return (
        <>
            <div>
                <Alert
                message={(
                    <>
                        <div>
                            <div>
                                <span>Change Date From </span>
                                <span style={{ color : 'var(--main-color)', fontWeight : 500, }}>{moment(ticket?.visit_date)?.format("Do MMMM YYYY")} </span>
                                <span>to </span>
                                <span style={{ color : 'var(--main-color)', fontWeight : 500, }}>{moment(visit_date)?.format("Do MMMM YYYY")}</span>
                            </div>

                            {
                                moment(ticket?.visit_date)?.isSame(visit_date) && (
                                    <>
                                        <div>
                                            <span style={{ fontSize : 12, }}>Please choose a date that is not the same as the current visit date.</span>
                                        </div>
                                    </>
                                )
                            }
                            
                            {
                                dateRedux?.preference &&
                                (
                                    <>
                                        <div style={{marginTop:12,}}>
                                            <Tag style={{border : 'none', color : 'var(--main-color)', fontWeight:'normal', }} color={'var(--lighten-main-color)'}>
                                                {`${dateRedux?.preference?.preference_group ? (`${dateRedux?.preference?.preference_group?.name} - `) : ''} ${(dateRedux?.preference?.name)}`}
                                            </Tag>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </>
                )}
                {...moment(ticket?.visit_date)?.isSame(visit_date) ? ({ type : 'error', }) : ({ style : ({ background : 'var(--secondary-button-color)', border : 'none', }) })}
                />
                
                
                <div style={{height: ('calc(100vh - 300px)'),}}>
                    <div
                    className='summary-cart-items scroll-item' 
                    style={{
                        overflowY:'auto',
                        flexGrow : 1, 
                        maxHeight: 'calc(100% )',
                    }}
                    >
                        <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                            <List
                            size='large'
                            dataSource={state?.user_prices}
                            renderItem={(item, index) => {
                                const { discounted_price, original_price, quantity, ticket_user_type, price_diff } = item ?? {};
                                const total_admin_fees = numberHelper?.commaNumber(((state?.admin_fees ?? 0) * quantity), 2);
                                const total_price_diff = numberHelper?.commaNumber((price_diff * quantity), 2);
                                return (
                                    <List.Item>
                                        <EntranceMotion style={{width : '100%'}} delay={index * 0.1}>
                                            <div style={{display:'flex', flexWrap:'wrap', gap:24, alignItems:'start', }}>
                                                <div style={{maxWidth:200}}>
                                                    <div>
                                                        <span className='ticket-label' style={{fontWeight:500, fontSize:16}}>
                                                            {`${ticket_user_type?.name} ${
                                                                ticket_user_type?.min_age != null ?
                                                                (
                                                                    ticket_user_type?.max_age ?
                                                                    (
                                                                        `(${ticket_user_type?.min_age} - ${ticket_user_type?.max_age})`
                                                                    )
                                                                    :
                                                                    (
                                                                        `(${ticket_user_type?.min_age} - 100+)`
                                                                    )
                                                                ) : ""
                                                            }`}
                                                        </span>
                                                    </div>

                                                    <div>
                                                        <span style={{color:'var(--secondary-text-color)'}}>{`x${quantity}`}</span>
                                                    </div>

                                                    <div>
                                                        <div>
                                                            <span style={{fontSize:12,}}>{`Admin Fees: ${currency}${state?.admin_fees ?? 0}`}</span>
                                                        </div>
                                                        <div>
                                                            <span style={{fontSize:12,}}>{`Price Differences: ${currency}${numberHelper?.commaNumber((price_diff ?? 0), 2)}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
            

                                                <div style={{marginLeft:'auto', textAlign:'end'}}>
                                                    <div>
                                                        <Space>
                                                            <div>
                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>
                                                                    Total Admin Fees:
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span style={{fontWeight:500, fontSize:16}}>
                                                                    {`${currency}${total_admin_fees}`}
                                                                </span>
                                                            </div>
                                        
                                                        </Space>
                                                    </div>
                                                    <div>
                                                        <Space>
                                                            <div>
                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>
                                                                    Total Price Differences:
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span style={{fontWeight:500, fontSize:16}}>
                                                                    {`${currency}${total_price_diff}`}
                                                                </span>
                                                            </div>
                                        
                                                        </Space>
                                                    </div>
                                                    
                                                </div>

                                                
                                            </div>
                                        
                                        </EntranceMotion>
                                    </List.Item>
                                )
                            }}
                            />
                        </Spin>
                    </div>
                </div>

                <div style={{padding:'12px 24px 0 24px', borderTop : '1px solid #eeedf2'}}>
                    <div style={{display:'flex'}}>
                        <Alert
                        style={{width:'100%'}}
                        message={(
                            <>
                                <span>Update ticket email will sent to </span>
                                <span style={{color:'var(--secondary-text-color)'}}>{ticket?.email}</span>
                            </>
                        )} />
                
                    </div>
                    <div style={{ display:'flex', alignItems:'center', marginTop:8, }}>
                        <div>
                            <div style={{ textAlign:'right'}}>
                                <span style={{color:'var(--secondary-text-color)'}}>Total</span>
                            </div>
                        </div>
                        <div style={{marginLeft:'auto'}}>
                            <div>
                                <span className='ticket-label' style={{fontSize:16, fontWeight:500}}>
                                    {`${currency}${numberHelper?.commaNumber(state?.total_price, 2)}`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderSummary;