import { Image, Space } from 'antd';
import React from 'react';

const OpenDateStepNoItem = ({no, text, image, }) => {
    return (
        <>
            <Space>
                <div className='step-no'>
                    <Image preview={false} src={image} style={{borderRadius : 6}} />
                    {/* <span className='ticket-label'>{no}</span> */}
                </div>
                <div className='step-label'>
                    <span className='ticket-label'>{text}</span>
                </div>
            </Space>
        
        </>
    );
}

export default OpenDateStepNoItem;