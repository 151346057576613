import { Affix, Button, Image, Layout, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import PurchaseContent from './purchase_content';
import { useDispatch, useSelector } from 'react-redux';
import { reset_purchase_detail, set_purchase_detail } from '../../../actions/purchase.action';
import axios from 'axios';
import specialLinkService from '../../../services/ticketing/special_link.service';
import { set_park, set_special_link } from '../../../actions/ticket.action';
import moment from 'moment';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';

const PurchaseLayout = ({ park_code, special, }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : true,
        park : null,

        // special link
        special_link : null,
        special_link_expired : false,
        special_link_used : false,
    })

    const fetchPark = async () => {
        setState(state => ({...state, loading : true}));
        const parkResponse = await parkService.getPark({
            park_code : park_code,
            is_server_maintenance : false,
        });
        const park_id = parkResponse?.parks?.park_id

        //fetch special link if exist
        let special_link_expired = false;
        let special_link_used = false;
        let get_special_link;
        if(special){
            const specialLinkResponse = await specialLinkService.validateSpecialLink({ unique_code : special, park_id : park_id });
            const special_link = specialLinkResponse?.special_link
            dispatch(set_special_link(special_link));

            //check if special link already exipred
            const time_remaining = (moment(special_link?.expired_datetime)?.diff(moment(), 'minutes'));
            special_link_expired = time_remaining <= 0;
            special_link_used = special_link?.is_used;
            get_special_link = special_link;
        }
        
        dispatch(set_purchase_detail({ park_id : park_id, open_date : false, }));
        dispatch(set_park(parkResponse?.parks))
        setTimeout(() => {
            setState(state => ({...state,
                loading : false,
                park : parkResponse?.parks,
                special_link_expired : special_link_expired,
                special_link_used : special_link_used,
                special_link : get_special_link,
            }))
        }, 500)
    }

    useEffect(() => {
        fetchPark();
    }, [])

    useEffect(() => { //set header of axios
        axios.defaults.headers.common['park_id'] = state?.park?.park_id;

        return ()=>{
            axios.defaults.headers.common['park_id'] = null;
        }
    }, [state?.park])

    return (
        <>
            <div className='purchase-content'>
                {
                    (special && (state?.special_link_expired || state?.special_link_used)) ?
                    (
                        <>
                            <SimpleNotFoundComponent
                            redirect_url={state?.park?.base_url}
                            redirect_name={`Continue without special link`}
                            title={`Special Link Has ${state?.special_link_expired ? "Expired" : 'Used'}`}
                            description={`Special link expires after ${state?.special_link?.minutes ?? 0} minutes, please request a new link from an administrator.`}
                            />
                        </>
                    )
                    :
                    (
                        <>
                            <PurchaseContent park={state?.park} loading={state?.loading} />
                        </>
                    )
                }
            </div>
        </>
    );
}

export default PurchaseLayout;