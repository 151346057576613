import dayjs from "dayjs";

function generateSeed(min, max){
    const min_seed = min ?? 0;
    const max_seed = max ?? 1000;
    return Math.floor(Math.random() * (max_seed - min_seed + 1)) + min_seed;
}

function isMobileScreen(){
    const isMobile = window.innerWidth <= 768;

    return isMobile;
}

function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
    }, {});
}

function tryParseJSONObject (jsonString){
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
}

function parseDate (date_string){
    let formatted_date;
    if(date_string){
        const parsed_date = (dayjs(date_string, 'DD/MM/YYYY'));
        formatted_date = parsed_date?.format("YYYY-MM-DD");
    }
    

    return formatted_date;
}

function isColorDark(color){
    // Remove the "#" symbol if present
  color = color.replace("#", "");

  // Parse the color components
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  // Calculate the luminance using the relative luminance formula
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Compare the luminance against a threshold (0.5 in this example)
  return luminance < 0.5;
}

// Function to add opacity to a hex color
function hexToRgba(hex, opacity) {
    // Remove the '#' if present
    hex = hex.replace(/^#/, '');

    // Parse the hex value to RGB
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Calculate the RGBA values
    const rgba = `rgba(${r}, ${g}, ${b}, ${opacity ?? 1})`;

    return rgba;
}

export default {
    generateSeed,
    isMobileScreen,
    _base64ToArrayBuffer,
    groupBy,
    tryParseJSONObject,
    parseDate,
    isColorDark,
    hexToRgba,
}