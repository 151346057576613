import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function validateSpecialLink(params = {}){
    const { unique_code, park_id } = params;
    const specialLinkResponse = await axios.get(`ticket/special_link/${unique_code}/park/${park_id}/validate`, { withCredentials : true });
    return specialLinkResponse?.data;
}

export default {
    validateSpecialLink,
}