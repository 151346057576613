import React from 'react';
import GreyContainer from '../../../helpers/components/grey_container';
import { Button, Col, Image, Row, Space } from 'antd';
import AlertText from '../../ticket/components/alert_text';
import { Link } from 'react-router-dom';
import globalHelper from '../../../helpers/functions/global.helper';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { RightOutlined } from '@ant-design/icons';

const WhatToDoNext = ({ticket}) => {
    return (
        <>
            <div style={{textAlign:'left'}}>
                {/* <div>
                    <span style={{fontSize: 24, fontWeight:'bold', color:'var(--main-color)'}}>What to do next?</span>
                </div> */}
                <div style={{marginTop:12}}>
                    <Row gutter={[20, 20]} justify={'center'}>
                        <Col xs={24} md={12}>
                            <Link to={`/ticket/face/${ticket?.token}?c=1`}>
                                <GreyContainer className="grey-card" height={"100%"} padding={0}>
                                    <div style={{width:'100%', display:'flex', flexDirection:'column', height:'100%'}}>
                                        <div style={{padding:24}}>
                                            <div>
                                                <div>
                                                    <Space style={{ color:'#4d4f5e' }}>
                                                        <div>
                                                            <span style={{fontSize:20, fontWeight:'bold',}}>Upload Your Selfies</span>
                                                        </div>
                                                        <div>
                                                            <RightOutlined className='right-arrow' />
                                                        </div>
                                                    </Space>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className='home-background' style={{textAlign:'center', overflow:'hidden', marginTop:'auto'}}>
                                            <EntranceMotion>
                                                <div style={{marginTop:12}}>
                                                    <Image className='features-image' src='/cibanners/selfie-guide.png' preview={false} style={{width: globalHelper?.isMobileScreen() ? 120 : 200}} />
                                                </div>
                                            </EntranceMotion>
                                        </div>
                                    </div>
                                </GreyContainer>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default WhatToDoNext;