import React from 'react';
import ContainerComponent from './container.component';
import { Button, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import EntranceMotion from './entrance_motion';

const SimpleNotFoundComponent = ({ title, description, redirect_url, redirect_name, isBack, height = 'calc(100vh - var(--header-height) - 40px)', }) => {
    const history = useHistory();
    const redirectLink = () => {
        window.location.href = redirect_url;
    }

    return (
        <>
            <ContainerComponent span={8}>
                <div className='mobile-padding-24' style={{height : height, display: 'flex', alignItems : 'center', justifyContent:'center'}}>
                    <EntranceMotion>
                        <Space size={12} direction='vertical' style={{width : '100%',}}>
                            <div>
                                <span className='ticket-label' style={{fontWeight:500, fontSize:24,}}>{title}</span>
                            </div>
                            <div>
                                <span style={{fontSize:16, color : 'var(--secondary-text-color)',}}>{description}</span>
                            </div>
                            {
                                isBack ?
                                (
                                    <>
                                        <div style={{marginTop:24,}}>
                                            <Button onClick={() => history.goBack()} size='large' type='primary' style={{width : '100%', color: "var(--main-text-color)",}}>{'Go Back'}</Button>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        {
                                            redirect_url &&
                                            (
                                                <>
                                                    <div style={{marginTop:24,}}>
                                                        <Button onClick={redirectLink} size='large' type='primary' style={{width : '100%', color: "var(--main-text-color)",}}>{redirect_name}</Button>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Space>
                    </EntranceMotion>
                </div>
            </ContainerComponent>
        </>
    );
}

export default SimpleNotFoundComponent;