import React, { useEffect, useState } from 'react';
import TicketInfo from './ticket_info';
import ticketService from '../../../services/ticketing/ticket.service';
import { LoadingOutlined } from '@ant-design/icons';
import { Divider, } from 'antd';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import ContactInfo from './contact_info';
import PaymentDetail from './payment_detail';
import PaymentInfo from './payment_info';
import UploadFaceGuide from './upload_face_guide';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';

const TicketLayout = ({ ticket_unique_no }) => {
    const [state, setState] = useState({
        loading : true,
        ticket : null,
    })

    const fetchTicket = async () => {
        setState(state => ({...state, loading : true}));
        const ticketResponse = await ticketService.getTicket({ ticket_unique_no });

        const ticket = ticketResponse?.tickets?.length > 0 && ticketResponse?.tickets[0];

        setTimeout(() => {
            setState(state => ({...state, loading : false, ticket : ticket}));
        }, 500);
    }

    useEffect(() => {
        fetchTicket();
    }, [])
    return (
        <>
            <div>
            {
                state?.loading ?
                (
                    <>
                        <div className='mobile-padding-24'>
                            <LoadingOutlined style={{fontSize:20}} />
                        </div>
                    </>
                )
                :
                (
                    <>
                        {
                            state?.ticket ?
                            (
                                <>
                                    <div style={{textAlign:'left'}}>
                                        <div className='mobile-padding-left-right-24' style={{paddingTop:12,}}>
                                            <div style={{display:'flex', flexFlow:'wrap', alignItems:'center'}}>
                                                <div>
                                                    <span
                                                    className='ticket-label ticket-title h1'
                                                    style={{
                                                        fontWeight:'bold',
                                                    }}
                                                    >
                                                        Ticket for <span style={{color:'var(--main-color)'}}>{state?.ticket?.park?.name}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <Divider style={{margin: '12px 0'}}  />
                                        <div className='mobile-padding-left-right-24' style={{marginTop:12}}>
                                            <UploadFaceGuide link={`/ticket/face/${state?.ticket?.unique_no}`} />
                                        </div>

                                        <Divider style={{margin: '12px 0' }}  />
                                        {/* Ticket Information */}
                                        <div className='mobile-padding-left-right-24' >
                                            <PageHeaderComponent title={<span style={{color:'var(--main-color)', fontWeight:'bold'}}>Ticket Information</span>} />
                                            <div style={{marginTop:12}}>
                                                <TicketInfo ticket={state?.ticket} />
                                            </div>
                                        </div>

                                        {/* Contact Info*/}
                                        <div className='mobile-padding-left-right-24' style={{marginTop:42}}>
                                            <PageHeaderComponent title={<span style={{color:'var(--main-color)', fontWeight:'bold'}}>General Admission</span>} />
                                            <div style={{marginTop:12}}>
                                                <ContactInfo ticket={state?.ticket} />
                                            </div>
                                        </div>


                                        {/* Payment Detail */}
                                        <div className='mobile-padding-left-right-24' style={{marginTop:42}}>
                                            <PageHeaderComponent title={<span style={{color:'var(--main-color)', fontWeight:'bold'}}>Payment Details</span>} />
                                            <div style={{marginTop:12}}>
                                                <PaymentInfo ticket={state?.ticket} />
                                                <Divider />
                                                <PaymentDetail
                                                add_ons={state?.ticket?.add_ons}
                                                ticket_users={state?.ticket?.ticket_users}
                                                total_amount={state?.ticket?.payment_amount}
                                                promotion_code={state?.ticket?.promotion_code}
                                                actual_price={state?.ticket?.actual_price}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    <div className='mobile-padding-left-right-24'>
                                        <SimpleNotFoundComponent
                                        title={`Ticket Not Found`}
                                        isBack
                                        />
                                    </div>
                                </>
                            )
                        }
                        
                    </>
                )
            }
            </div>
        </>
    );
}

export default TicketLayout;