import React, { useEffect, useState } from 'react';
import preferenceService from '../../../services/config/preference.service';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { set_preference } from '../../../actions/date.action';

const PreferenceSelect = ({ park_applicable_ticket_type_id, ticket, }) => {
    const dispatch = useDispatch();
    const dateRedux = useSelector(state => (state?.date));
    const visit_date = dateRedux?.visit_date;
        const [state, setState] = useState({
        loading : false,
        preference_groups : [],
        selected_preference_id : null,
    })

    const selectPreference = async (preference) => {
        setState(state => ({...state, selected_preference_id : preference?.preference_id, }));
        dispatch(set_preference(preference));
    }

    const fetchPreferences = async () => {
        setState(state => ({...state, loading : true,}));
        const preferenceResponse = await preferenceService?.getPreferences({
            park_applicable_ticket_type_id,
            visit_date,
            is_rescheduleable : 1,
            preference_group_id : ticket?.preference?.preference_group_id,
        });
        const { preference_groups, } = preferenceResponse;
        setState(state => ({...state, loading : false, preference_groups : preference_groups, }));

        const default_preference = preference_groups?.flatMap(x => x.preferences)?.find(x => x?.preference_id === ticket?.preference_id);
        await selectPreference(default_preference);
    }

    useEffect(() => {
        visit_date && fetchPreferences();
    }, [visit_date])

    return (
        <>
            {
                state?.preference_groups?.length > 0 &&
                (
                    <>
                        <div>
                            <Row gutter={[12, 12]}>
                            {
                                state?.preference_groups?.map((preference_group, index) => {
                                    const { preferences, name : preference_group_name, } = preference_group;
                                    return (
                                        <Col xs={24} md={24} lg={24} key={index}>
                                            <div>
                                                <div style={{ textAlign : 'start', }}>
                                                    <span style={{fontWeight:500, fontSize:16,}}>{preference_group_name}</span>
                                                </div>
                                                <div style={{marginTop: 12,}}>
                                                    <Row gutter={[8, 8]}>
                                                    {
                                                        preferences?.map((preference, pindex) => {
                                                            const { preference_id, name : preference_name, } = preference;
                                                            const selected_preference = preference_id === state?.selected_preference_id;
                                                            return (
                                                                <Col span={12} key={pindex}>
                                                                    <Card
                                                                    onClick={() => selectPreference(preference)}
                                                                    className='ticket-type-card'
                                                                    style={{ cursor : 'pointer', ...(selected_preference && ({ background : 'var(--lighten-main-color)', color : 'var(--main-color)', borderColor : 'var(--main-color)' }))}}
                                                                    bodyStyle={{ padding : 8, }}
                                                                    >
                                                                        <div style={{display : 'flex', }}>
                                                                            <div>
                                                                                <span>{preference_name}</span>
                                                                            </div>
                                                                            {
                                                                                selected_preference &&
                                                                                (
                                                                                    <div style={{marginLeft:'auto'}}>
                                                                                        <span><CheckOutlined /></span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                            </Row>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default PreferenceSelect;