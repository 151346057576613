import React, { useEffect } from 'react';
import PurchaseLayout from './components/purchase_layout';
import { useLocation, useParams } from 'react-router-dom';
import './purchase.css';
import './purchase_v1.css'
import { useDispatch } from 'react-redux';
import { reset_purchase_detail } from '../../actions/purchase.action';
import { set_special_link } from '../../actions/ticket.action';

const PurchaseIndex = () => {
    // const { park_code } = useParams();
    const park_code = process.env.REACT_APP_PARK_CODE;

    //get query params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const special = queryParams.get('special');
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            //reset
            dispatch(reset_purchase_detail());
            //clear localstorage
            localStorage.removeItem('purchase_item');
        }
    }, [])

    return (
        <>
            <PurchaseLayout park_code={park_code} special={special} />
        </>
    );
}

export default PurchaseIndex;