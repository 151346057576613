import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function calculateChangeDateUserPrice({ticket_unique_no, new_date}){
    const changeDatePriceResponse = await axios.get(`ticket/change_date/price/ticket/${ticket_unique_no}/new_date/${new_date}`, { withCredentials : true });
    return changeDatePriceResponse?.data;
}

async function createChangeDate(ticket_unique_no, params,){
    const changeDateResponse = await axios.post(`ticket/change_date/${ticket_unique_no}`, params, { withCredentials : true });
    return changeDateResponse?.data;
}

async function fetchDateChange(query){
    const queryString = serviceHelper.queryString(query);
    const changeDateResponse = await axios.get(`ticket/change_date/get?${queryString}`, { withCredentials : true });

    return changeDateResponse?.data;
}


export default {
    calculateChangeDateUserPrice,
    createChangeDate,
    fetchDateChange,
}