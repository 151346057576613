import { Col, Row, Space } from 'antd';
import React from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import { useSelector } from 'react-redux';

const PaymentBreakdown = ({total_amount, promotion_code, actual_price}) => {
    const ticketRedux = useSelector(state => state.ticket);
    const currency = ticketRedux?.park?.currency?.symbol;
    const discount_amount = isNaN((actual_price - total_amount)) ? 0 : (actual_price - total_amount)?.toFixed(2);

    const breakdowns = [{
        label : 'Sub Total',
        value : `${currency}${actual_price}`,
        size : 16,
        is_promo : true,
    },{
        label : 'Discount',
        value : (`-${currency}${discount_amount}`),
        size : 16,
        color : 'var(--main-color)',
        is_promo : true,
    },{
        label : 'Total',
        value : `${currency}${total_amount}`,
        labelSize : 18,
        size : 20,
        is_promo : false,
    }]


    return (
        <>
            {
                breakdowns?.map((breakdown, index) => {
                    const { label, value, labelSize, size, color, is_promo } = breakdown ?? {};
                    const check_promo = is_promo ? promotion_code : true;
                    return (
                        <>
                        {
                            check_promo &&
                            (
                                <>
                                    <Row align={'middle'} key={index} style={{marginTop:2}}>
                                        <Col offset={globalHelper?.isMobileScreen() ? 8 : 16} xs={8} md={4} span={4}>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)', fontSize: labelSize ?? 14}}>{label}</span>
                                            </div>
                                        </Col>
                                        <Col xs={8} md={4} span={4}>
                                            <div style={{textAlign:'right',}}>
                                                <Space className='ticket-label' style={{fontSize: (size ?? 16), ...(color && { color : color })}}>
                                                    
                                                    <div>
                                                        <span>
                                                            {value}
                                                        </span>
                                                    </div>
                                                
                                                </Space>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                       
                        </>
                    )
                })
            }
        </>
    );
}

export default PaymentBreakdown;