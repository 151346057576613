import Input from 'antd/es/input/Input';
import React, { useEffect, useState } from 'react';
import TicketSearchBottomLayout from '../mobiles/ticket_search_bottom_layout';
import parkService from '../../../services/config/park.service';
import { Button, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ticketService from '../../../services/ticketing/ticket.service';
import { useHistory } from 'react-router-dom';

const TicketNoSearch = ({ park_code, }) => {
    const { innerWidth } = window;
    const history = useHistory();
    const [state, setState] = useState({
        loading : false,
        park : null,

        search_input : null,
    });

    const changeInput = (input) => {
        setState(state => ({...state, search_input : input}));
    }

    const fetchPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService?.getPark({
            park_code,
        });

        setTimeout(() => {
            setState(state => ({...state, park : parkResponse?.parks, loading : false,}));
        }, 200);
    }

    const redirectToTicket = async () => {
        const search_input = state?.search_input;

        // get the first part of input
        const unique_no = search_input;
        const ticketResponse = await ticketService?.getTicket({ ticket_unique_no : unique_no, is_salt : 1, });
        const { tickets, } = ticketResponse;
        history.push(`/date/change/${tickets?.[0]?.token ?? search_input}?c=1`)
    }

    useEffect(() => {
        fetchPark();
    }, [])

    return (
        <>
            <div className='mobile-padding-12'>
                <Spin indicator={<LoadingOutlined />} spinning={state?.loading}>
                    <div style={{textAlign:'left'}}>
                        <div style={{display:'flex', flexFlow:'wrap', alignItems:'center'}}>
                            <div>
                                <span
                                className='ticket-label ticket-title h1'
                                style={{
                                    fontWeight:'bold',
                                    fontSize: '1.5rem'
                                }}
                                >
                                    Change Visit Date <span style={{color:'var(--main-color)'}}>{state?.park?.name}</span>
                                </span>
                            </div>
                        </div>
                        
                        <div style={{marginTop:4}}>
                            <span style={{color : 'var(--secondary-text-color)', fontWeight: 500,}}>Please enter your transaction number to continue</span>
                        </div>
                        
                    </div>

                    <div style={{marginTop:24}}>
                        <div>
                            <div>
                                <Input
                                style={{border:'2px solid rgb(203, 213, 224)'}}
                                size='large'
                                onChange={(e) => changeInput(e.target.value)}
                                />
                            </div>
                            
                            {
                                innerWidth > 768 &&
                                (
                                    <>
                                        <div style={{marginTop:12,}}>
                                            <Button
                                            size='large'
                                            type='primary'
                                            style={{width:'100%'}}
                                            onClick={redirectToTicket}
                                            >
                                                <span style={{fontWeight:500,}}>
                                                    Search Tickets
                                                </span>
                                            </Button>
                                        </div>
                                    </>
                                )
                            }
                        </div>

                        <div style={{textAlign:'start', marginTop:2,}}>
                            <span style={{color : 'var(--secondary-text-color)', fontWeight: 500,}}>
                                Transaction Number can be found on ticket purchase confirmation email
                            </span>
                        </div>
                    </div>
                </Spin>
            </div>


            {/* mobile view */}
            <TicketSearchBottomLayout onClick={redirectToTicket} input={state?.search_input} />
        </>
    );
}

export default TicketNoSearch;