import React from 'react';
import { useParams } from 'react-router-dom';
import ContainerComponent from '../../helpers/components/container.component';
import PaymentDateLayout from './dates/payment_date_layout';

const PaymentDateIndex = () => {
    const { running_number } = useParams();
    return (
        <>
            <ContainerComponent span={12} bottom={20} top={20}>
                <PaymentDateLayout running_number={running_number} />
            </ContainerComponent>
        </>
    );
}

export default PaymentDateIndex;