import { Col, Row } from 'antd';
import React from 'react';

const ContainerComponent = ({children, span, top, bottom}) => {
    const { innerWidth } = window;
    return (
        <>
            <Row justify='center' align='center' style={{
                width:'100%',
                marginTop:(innerWidth <= 768 ? 0 : (top ?? 'auto')),
                marginBottom : (innerWidth <= 768 ? 0 : (bottom ?? 'auto'))
            }}
            >
                <Col xs={24} md={22} xl={span ?? 24} span={24}>
                    <div style={{margin:'0 auto'}}>
                        {children}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ContainerComponent;