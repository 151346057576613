import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_purchase_error } from '../../../actions/purchase_error.action';
import { set_purchase_detail } from '../../../actions/purchase.action';
import { AutoComplete, Form, Input } from 'antd';
import OpenDateStepNoItem from './open_date_step_no_item';

const OpenDateVisitor = () => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state?.purchase);
    const purchaseErrorRedux = useSelector(state => state?.purchase_error);

    const [option, setOption] = useState([]);

    const changeDetail = (key, value) => {
        if(purchaseErrorRedux[key]){
            dispatch(set_purchase_error({[key] : false,}))
        }

        dispatch(set_purchase_detail({
            [key] : value
        }))
    }

    return (
        <>
            <div style={{ textAlign : 'start', marginTop : 42, }}>
                <OpenDateStepNoItem text={'Contact Info'} image={'cibanners/step/contact.png'} emoji={<>🤙</>}/>
                <div style={{ marginTop : 12, }}>
                    <Form
                    layout='vertical'
                    >
                        <div>
                            <Form.Item label="Name">
                                <Input
                                value={purchaseRedux?.name}
                                {...purchaseErrorRedux?.name && ({ status : "error" })}
                                onChange={(e) => changeDetail('name', e?.target?.value)}
                                placeholder='John Doe'
                                size='large'
                                style={{
                                    maxWidth: '100%', 
                                    ...purchaseErrorRedux?.name ? 
                                    ({ background: '#ffeded' })
                                    : ({ background : 'var(--secondary-button-color)', border : '1px solid transparent', })
                                }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item name={'email'} label="Email Address">
                                <AutoComplete
                                value={purchaseRedux?.email}
                                defaultValue={purchaseRedux?.email}
                                children={
                                    <Input
                                    id="input_email"
                                    {...purchaseErrorRedux?.email && ({ status : "error" })}
                                    type='email' 
                                    // defaultValue={purchaseRedux?.email}
                                    // value={purchaseRedux?.email}
                                    // onChange={(e) => changeDetail('email', e?.target?.value)}
                                    placeholder='test@example.com'
                                    size='large'
                                    style={{
                                        maxWidth: '100%',
                                        fontSize : 16,
                                        ...purchaseErrorRedux?.email ? 
                                        ({ background: '#ffeded' })
                                        : ({ background : 'var(--secondary-button-color)', border : '1px solid transparent', })
                                    }}
                                    />
                                }
                                dropdownStyle={{ maxWidth : 400, }}
                                onChange={(value) => {
                                    let selections = [];
                                    if (value && value.indexOf('@') >= 0) {
                                        selections = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com'];
                                    }
                                    const filtered_selections = selections?.filter(domain => domain.startsWith(value.split('@').pop()));
                                    const options = filtered_selections?.map((domain, index) => ({
                                        key : index,
                                        // value,
                                        value : `${value}${domain.replace(value.split('@').pop(), '')}`,
                                        label : `${value}${domain.replace(value.split('@').pop(), '')}`,
                                    }));
                                    setOption(options);
                                    changeDetail('email', value)
                                }}
                                options={option}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default OpenDateVisitor;