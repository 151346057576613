import { Affix, Button, Image, Space } from 'antd';
import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { reset_purchase_detail } from '../../../actions/purchase.action';
import { useDispatch, useSelector } from 'react-redux';

const CartDetail = ({ park }) => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state?.purchase);

    const startOver = () => {
        dispatch(reset_purchase_detail(park?.park_id));
        localStorage?.removeItem('purchase_item');
    }

    return (
        <>
            <div>
                <Button
                type='text'
                style={{ width : '100%', }}
                // size='small'
                onClick={startOver}
                >
                    <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>
                        Reset
                    </span>
                </Button>
            </div>
        </>
    );
}

export default CartDetail;