import React from 'react';

const GreyContainer = ({children, height, padding, className}) => {
    return (
        <>
            <div
            className={className}
            style={{
                background:'var(--secondary-button-color)', 
                width:'100%', 
                height:(height ?? 'auto'), borderRadius:12, 
                // marginTop:12,
                padding : padding ?? 24,
            }}
            >{children}</div>
        </>
    );
}

export default GreyContainer;