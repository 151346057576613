import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function generatePaymentLink(park_code, params = {}){
    const linkResponse = await axios.post(`payment/url/${park_code}`, params, { withCredentials : true });
    return linkResponse?.data;
}

async function getPaymentStatus(){
    const paymentStatusResponse = await axios.get(`config/other/payment_status`, { withCredentials : true, });
    return paymentStatusResponse?.data;
}

export default {
    generatePaymentLink,
    getPaymentStatus,
}