import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import ResultItem from './result_item';
import { Col, Row, Space, Tag } from 'antd';
import SubResultItem from './sub_result_item';
import { CheckOutlined } from '@ant-design/icons';

const PaymentInfo = ({ ticket }) => {
    return (
        <>
            {/* detail */}
            <div>
                <Row gutter={[20, 20]}>
                    <Col xs={24} sm={12} md={8} span={8}>
                        <EntranceMotion initialY={-50} duration={0.5}>
                            <ResultItem icon={'wallet.svg'} title="Payment" value={
                                <>
                                    <div>
                                        <Space direction='vertical' style={{width:'100%'}} size={12}>
                                            <SubResultItem title="Reference No" value={ticket?.running_number} />
                                            <SubResultItem title="Status" value={
                                                <Tag

                                                {...ticket?.payment_status?.is_payment_complete && ({icon: <CheckOutlined />}) }
                                                color={ticket?.payment_status?.is_payment_complete ? 'green' : 'orange'}
                                                style={{border:'none', marginTop:4, paddingLeft:12, paddingRight:12, borderRadius:4}}
                                                >
                                                    <span style={{ fontWeight:'500'}}>
                                                    {ticket?.payment_status?.name}
                                                    </span>
                                                </Tag>
                                            } />
                                        </Space>
                                    </div>
                                </>
                            } />
                        </EntranceMotion>
                    </Col>
                    
                    {
                        ticket?.promotion_code &&
                        (
                            <Col xs={24} sm={12} md={8} span={8}>
                                <ResultItem icon={'discount.svg'} title="Discount" value={
                                    <>
                                        <div>
                                            <Space direction='vertical' style={{width:'100%'}} size={12}>
                                                <SubResultItem title="Promotion Name" value={ticket?.promotion_code?.name} />
                                                <SubResultItem title="Promo Code" value={ticket?.promotion_code?.promo_code} />
                                            </Space>
                                        </div>
                                    </>
                                } />
                            </Col>
                        )
                    }
                    

                </Row>
            </div>
        </>
    );
}

export default PaymentInfo;