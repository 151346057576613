import { Button, InputNumber, List, Popover, Space, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import numberHelper from '../../../helpers/functions/number.helper';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { set_purchase_detail } from '../../../actions/purchase.action';

const OpenDateTicketUserList = ({ park_applicable_ticket_users = [], }) => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state?.purchase);
    const ticketRedux = useSelector(state => state?.ticket);
    const currency = ticketRedux?.park?.currency?.symbol;

    const calculateEntitledQuantity = ({quantity, free_tickets}) => {
        let is_entitled = true;
        let entitled_quantity = null;
        //loop through to check entitled tickets
        for(const free_ticket of free_tickets){
            let required_ticket_quantity = 0;
            for(const free_ticket_require of free_ticket?.free_ticket_rule_requires){
                // check how much ticket
                const each_req_ticket_quantity = purchaseRedux?.ticket_users?.find(x => x.ticket_applicable_user_id === free_ticket_require?.ticket_applicable_user_id)?.quantity ?? 0;
                required_ticket_quantity += each_req_ticket_quantity
            }
            // const required_ticket_quantity = purchaseRedux?.ticket_users?.find(x => x.ticket_applicable_user_id === free_ticket?.required_ticket_applicable_user_id)?.quantity;
            entitled_quantity = Math.floor(((required_ticket_quantity ?? 0) / free_ticket?.required_quantity) * free_ticket?.free_quantity);
            is_entitled = (quantity ?? 0) < (isNaN(entitled_quantity) ? 0 : entitled_quantity);
        }   
        

        return {
            entitled_quantity,
            is_entitled
        };
    }

    const addQuantity = (ticket_user_type_id, quantity) => {
        let ticket_users = purchaseRedux?.ticket_users;
        //check currently have this ticket user or not
        let existing_ticket_user = ticket_users?.find(x => x.ticket_user_type_id === ticket_user_type_id);
        if(existing_ticket_user){
            // const calculated_quantity = existing_ticket_user?.quantity + plus;
            const calculated_quantity = quantity;
            existing_ticket_user.quantity = calculated_quantity;
            let changed_quantity = ticket_users;
            if(calculated_quantity > 0){
                changed_quantity = ticket_users?.map((x) => {
                    return (x?.ticket_user_type_id === ticket_user_type_id) ? existing_ticket_user : x;
                })
            }else{
                changed_quantity = ticket_users?.filter((x) => {
                    return (x?.ticket_user_type_id != ticket_user_type_id);
                })
            }
            //add quantity
            ticket_users = changed_quantity;

        }else{
            //add a new object
            if(quantity > 0){
                const park_applicable_ticket_user = park_applicable_ticket_users?.find(x => x.ticket_user_type_id === ticket_user_type_id);
                const ticket_user_item = (park_applicable_ticket_user?.ticket_user_type);
                const name = (ticket_user_item?.name);
                
                // const price = calculatePrice({ price : park_applicable_ticket_user?.price, discount_obj, is_peak : state?.is_peak_discount, is_fixed_price : park_applicable_ticket_user?.is_fixed_price });
                const price = park_applicable_ticket_user?.price;
                ticket_users.push({
                    name : name,
                    price : price,
                    actual_price : park_applicable_ticket_user?.price,
                    ticket_user_type_id : ticket_user_type_id,
                    quantity : quantity,
                    ticket_applicable_user_id : park_applicable_ticket_user?.ticket_applicable_user_id
                })
            }
            
        }

        dispatch(set_purchase_detail({ticket_users}));
    }

    const recalculateTicketUserQuantity = () => {
        let purchase_ticket_users = purchaseRedux?.ticket_users;

        for(const purchase_ticket_user of purchase_ticket_users){
            const { quantity, ticket_user_type_id, } = purchase_ticket_user;
            const get_ticket_user = (park_applicable_ticket_users?.find(x => x.ticket_user_type_id === purchase_ticket_user?.ticket_user_type_id)); // this ticket users
            const entitled = calculateEntitledQuantity({ quantity, free_tickets : (get_ticket_user?.free_tickets), });
            const { entitled_quantity } = entitled;
            if(entitled_quantity != null){
                const is_change = quantity > entitled_quantity;
                purchase_ticket_user.quantity = is_change ? entitled_quantity : quantity;
            }
        }

        dispatch(set_purchase_detail({ticket_users : purchase_ticket_users}));
    }

    useEffect(() => {
        recalculateTicketUserQuantity()
    }, [purchaseRedux?.ticket_users])

    return (
        <>
            <List
            size='large'
            dataSource={park_applicable_ticket_users}
            renderItem={(item, index) => {
                const { ticket_user_type, price, descriptions, free_tickets, is_fixed_price, ticket_applicable_user_id, park_applicable_ticket_type_id } = item ?? {};
                const quantity = (purchaseRedux?.ticket_users?.find(x => x.ticket_user_type_id === ticket_user_type?.ticket_user_type_id)?.quantity);
                // const price_amount = calculatePrice({ price, discount_obj, is_peak : state?.is_peak_discount, is_fixed_price,});
                const price_amount = price;
                const { is_entitled, entitled_quantity } = calculateEntitledQuantity({quantity, free_tickets});


                // adding disabled
                const is_non_purchaseable_ticket = ((purchaseRedux?.promotion_code?.promotion_code_ticket_applicable_users?.find(x => x.ticket_applicable_user_id === ticket_applicable_user_id)?.is_applicable) && 
                (ticketRedux?.remaining_promo_tickets != null && ticketRedux?.remaining_promo_tickets <= 0));
                const calculating = (purchaseRedux?.promotion_code && ticketRedux?.price_calculating);

                // previously is using if remaining promo tickets is not null then (using &&)
                // change it to question mark operator (? and :) to ensure it will become null
                // so the quantity for max ticket will not affected
                const remaining_purchaseable_tickets = ticketRedux?.remaining_promo_tickets != (null || undefined) ? (ticketRedux?.remaining_promo_tickets + (quantity ?? 0)) : null;
                
                const adding_disabled = (
                    !is_entitled ||
                    (is_non_purchaseable_ticket) || // when is applicable for this promo code, remaining is not null and remaining is 0
                    calculating // when theres promotion code, only check this one
                )

                return (
                    <List.Item style={{ paddingInline : 0, }}>
                        <div style={{width:'100%'}}>
                            <div style={{display:'flex', flexWrap:'wrap', gap:24}}>
                                <div style={{ textAlign : 'start', }}>
                                    <div>
                                        <span className='ticket-label' style={{fontWeight:500, fontSize:16}}>
                                            {`${ticket_user_type?.name}`}
                                        </span>
                                    </div>
                                    <div>
                                        <span style={{color:'#6f7287'}}>
                                            {
                                                ticket_user_type?.min_age != null &&
                                                (
                                                    <>
                                                        {
                                                            ticket_user_type?.max_age ?
                                                            (
                                                                `${ticket_user_type?.min_age} - ${ticket_user_type?.max_age}`
                                                            )
                                                            :
                                                            (
                                                                `${ticket_user_type?.min_age} - 100+`
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </span>
                                    </div>
                                    <div>
                                        <span style={{color:'#6f7287'}}>
                                            { descriptions }
                                        </span>
                                    </div>
                                </div>

                                {
                                    (ticket_user_type?.ticket_user_type_id != 4) &&
                                    (
                                        <>
                                            <div style={{marginLeft:'auto'}}>
                                                <div>
                                                    <Space size={20}>
                                                        <span className='ticket-label' style={{fontWeight:500, fontSize:16}}>
                                                            {`${currency}${numberHelper?.commaNumber(price)}`}
                                                        </span>
                                                        {/* {
                                                            discount_obj || peak_day_discount?.length > 0 ?
                                                            (
                                                                <>
                                                                    <div>
                                                                        <div>
                                                                            <span className='ticket-label' style={{fontWeight:500, fontSize:16}}>
                                                                                {`${currency}${numberHelper?.commaNumber(price_amount)}`}
                                                                            </span>
                                                                        </div>
                                                                        <div style={{textAlign:'end'}}>
                                                                            <EntranceMotion>
                                                                                <span className='ticket-label'
                                                                                style={{fontSize:14, color:'var(--secondary-text-color)', textDecoration:'line-through'}}
                                                                                >
                                                                                    {`${currency}${numberHelper?.commaNumber(price)}`}
                                                                                </span>
                                                                            </EntranceMotion>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <span className='ticket-label' style={{fontWeight:500, fontSize:16}}>
                                                                        {`${currency}${numberHelper?.commaNumber(price)}`}
                                                                    </span>
                                                                </>
                                                            )
                                                        } */}
                                                        
                                                        <Space>
                                                            <div>
                                                                <Button
                                                                disabled={(!quantity || quantity <= 0)}
                                                                onClick={() => addQuantity(ticket_user_type?.ticket_user_type_id, ((isNaN(quantity) ? 0 : parseInt(quantity)) - 1))}
                                                                type='ghost'
                                                                style={{background:'#F8F7FA', padding:'4px 10px', opacity : ((!quantity || quantity <= 0) ? '0.5' : '1' )}}
                                                                >
                                                                    <MinusOutlined />
                                                                </Button>
                                                            </div>

                                                            <div>
                                                                {
                                                                    is_entitled ?
                                                                    (
                                                                        <>
                                                                            <Popover
                                                                            content={
                                                                                <>
                                                                                    <div style={{padding:8,}}>
                                                                                        <InputNumber
                                                                                        precision={0}
                                                                                        // disabled={(purchaseRedux?.promotion_code && ticketRedux?.price_calculating)}
                                                                                        type='number'
                                                                                        placeholder='Enter ticket quantity'
                                                                                        {...entitled_quantity && ({ max : entitled_quantity })}
                                                                                        {...remaining_purchaseable_tickets != null && ({ max : remaining_purchaseable_tickets, })}
                                                                                        value={quantity}
                                                                                        // onChange={(q) => doneTyping(ticket_user_type?.ticket_user_type_id, q)}
                                                                                        onChange={(q) => addQuantity(ticket_user_type?.ticket_user_type_id, q)} 
                                                                                        style={{width:'100%'}} 
                                                                                        />
                                                                                        <div>
                                                                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{descriptions}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            trigger={['click']}
                                                                            placement='bottom'
                                                                            overlayInnerStyle={{padding : 0,}}
                                                                            >
                                                                                <Button>{quantity ?? 0}</Button>
                                                                            </Popover>
                                                                        </>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <Button style={{ opacity : 0.5}}>{quantity ?? 0}</Button>
                                                                        </>
                                                                    )
                                                                }
                                                                
                                                            </div>
                                                            
                                                            <div>
                                                                <Tooltip showArrow={false} trigger={is_entitled ? [] : 'hover'} placement='bottomRight' title={descriptions}>
                                                                    <Button
                                                                    disabled={adding_disabled}
                                                                    onClick={() => addQuantity(ticket_user_type?.ticket_user_type_id, ((isNaN(quantity) ? 0 : parseInt(quantity)) + 1))}
                                                                    type='ghost' 
                                                                    style={{background:'#F8F7FA', padding:'4px 10px', opacity : ((adding_disabled) ? '0.5' : '1' )}}
                                                                    >
                                                                        <PlusOutlined />
                                                                    </Button>
                                                                </Tooltip>
                                                            </div>
                                                        </Space>
                                                    </Space>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </List.Item>
                )
            }}
            />
        </>
    );
}

export default OpenDateTicketUserList;