import { Alert, List, Space, Spin, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import dateService from '../../../services/ticketing/date.service';
import { LoadingOutlined, CalendarOutlined, ArrowRightOutlined } from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { set_final_price } from '../../../actions/date.action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const MobileUserSummary = ({ ticket, visit_date }) => {
    const dateRedux = useSelector(state => state.date);
    const currency = dateRedux?.park?.currency?.symbol;
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : true,
        user_prices : [],
        admin_fees : 0,
        total_price : 0,
    })

    const alertRef = useRef();
    const footerRef = useRef();

    const fetchCalculateChangeDatePrice = async () => {
        setState(state => ({...state, loading : true}));
        const changeDatePriceResponse = await dateService.calculateChangeDateUserPrice({ ticket_unique_no : ticket?.unique_no, new_date : visit_date });
        const { new_ticket_prices, process_fee, total_price } = changeDatePriceResponse  ?? {};
        setState(state => ({...state ,loading : false, user_prices : new_ticket_prices, admin_fees : process_fee, total_price : total_price,}));

        //set final price to redux
        dispatch(set_final_price(total_price));
    }

    useEffect(() => {
        fetchCalculateChangeDatePrice();
    }, [visit_date])

    return (
        <>
            <div style={{padding:'12px 24px',}}>
                <div ref={alertRef}>
                    <Alert
                    message={(
                        <>
                            <div>
                                <div>
                                    <span>Change Date From </span>
                                    <span style={{ color : 'var(--main-color)', fontWeight : 500, }}>{moment(ticket?.visit_date)?.format("Do MMMM YYYY")} </span>
                                    <span>to </span>
                                    <span style={{ color : 'var(--main-color)', fontWeight : 500, }}>{moment(visit_date)?.format("Do MMMM YYYY")}</span>
                                </div>

                                {
                                    moment(ticket?.visit_date)?.isSame(visit_date) && (
                                        <>
                                            <div>
                                                <span style={{ fontSize : 12, }}>Please choose a date that is not the same as the current visit date.</span>
                                            </div>
                                        </>
                                    )
                                }
                                
                                {
                                    dateRedux?.preference &&
                                    (
                                        <>
                                            <div style={{marginTop:12,}}>
                                                <Tag style={{border : 'none', color : 'var(--main-color)', fontWeight:'normal', }} color={'var(--lighten-main-color)'}>
                                                    {`${dateRedux?.preference?.preference_group ? (`${dateRedux?.preference?.preference_group?.name} - `) : ''} ${(dateRedux?.preference?.name)}`}
                                                </Tag>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </>
                    )}
                    {...moment(ticket?.visit_date)?.isSame(visit_date) ? ({ type : 'error', }) : ({ style : ({ background : 'var(--secondary-button-color)', border : 'none', }) })}
                    />
                </div>

                {
                    dateRedux?.preference &&
                    (
                        <>
                            <div style={{marginTop:12,}}>
                                <Tag style={{border : 'none', color : 'var(--main-color)', fontWeight:'normal', }} color={'var(--lighten-main-color)'}>
                                    {`${dateRedux?.preference?.preference_group ? (`${dateRedux?.preference?.preference_group?.name} - `) : ''} ${(dateRedux?.preference?.name)}`}
                                </Tag>
                            </div>
                        </>
                    )
                }
            </div>
            
            <div style={{height: (`250px`),}}>
                <div
                className='summary-cart-items scroll-item' 
                style={{
                    overflowY:'auto',
                    flexGrow : 1, 
                    maxHeight: 'calc(100%)',
                }}
                >
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <List
                        size='large'
                        dataSource={state?.user_prices}
                        renderItem={(item, index) => {
                            const { discounted_price, original_price, quantity, ticket_user_type, price_diff } = item ?? {};
                            const total_admin_fees = ((state?.admin_fees ?? 0) * quantity)?.toFixed(2);
                            const total_price_diff = (price_diff * quantity)?.toFixed(2);
                            return (
                                <List.Item>
                                    <EntranceMotion style={{width : '100%'}} delay={index * 0.1}>
                                        <div style={{display:'flex', flexWrap:'wrap', gap:24, alignItems:'start', }}>
                                            <div style={{maxWidth:120}}>
                                                <div>
                                                    <span className='ticket-label' style={{fontWeight:500, fontSize:14}}>
                                                        {`${ticket_user_type?.name} (${
                                                            ticket_user_type?.min_age != null &&
                                                            (
                                                                ticket_user_type?.max_age ?
                                                                (
                                                                    `Age ${ticket_user_type?.min_age} - ${ticket_user_type?.max_age}`
                                                                )
                                                                :
                                                                (
                                                                    `More than age ${ticket_user_type?.min_age}`
                                                                )
                                                            )
                                                        })`}
                                                    </span>
                                                </div>

                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>{`x${quantity}`}</span>
                                                </div>

                                                <div>
                                                    <div>
                                                        <span style={{fontSize:12,}}>{`Admin Fees: ${currency}${state?.admin_fees ?? 0}`}</span>
                                                    </div>
                                                    <div>
                                                        <span style={{fontSize:12,}}>{`Price Differences: ${currency}${(price_diff ?? 0)?.toFixed(2)}`}</span>
                                                    </div>
                                                </div>
                                            </div>
        

                                            <div style={{marginLeft:'auto', textAlign:'end'}}>
                                                <div>
                                                    <Space>
                                                        <div>
                                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>
                                                                Total Admin Fees:
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span style={{fontWeight:500, fontSize:14}}>
                                                                {`${currency}${total_admin_fees}`}
                                                            </span>
                                                        </div>
                                    
                                                    </Space>
                                                </div>
                                                <div>
                                                    <Space>
                                                        <div>
                                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>
                                                                Total Price Differences:
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span style={{fontWeight:500, fontSize:14}}>
                                                                {`${currency}${total_price_diff}`}
                                                            </span>
                                                        </div>
                                    
                                                    </Space>
                                                </div>
                                                
                                            </div>

                                            
                                        </div>
                                    
                                    </EntranceMotion>
                                </List.Item>
                            )
                        }}
                        />
                    </Spin>
                </div>
            </div>

            <div ref={footerRef} style={{padding:'12px 24px 0 24px', borderTop : '1px solid #eeedf2', }}>
                <div style={{display:'flex'}}>
                    <Alert message={(
                        <>
                            <span>Update ticket email will sent to </span>
                            <span style={{color:'var(--secondary-text-color)'}}>{ticket?.email}</span>
                        </>
                    )} />
            
                </div>
                <div style={{ display:'flex', alignItems:'center', marginTop:8, }}>
                    <div>
                        <div style={{ textAlign:'right'}}>
                            <span style={{color:'var(--secondary-text-color)'}}>Total</span>
                        </div>
                    </div>
                    <div style={{marginLeft:'auto'}}>
                        <div>
                            <span className='ticket-label' style={{fontSize:16, fontWeight:500}}>
                                {`${currency}${state?.total_price?.toFixed(2)}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileUserSummary;