import { Badge, Divider, Image, Result, Space, Tag } from 'antd';
import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import WhatToDoNext from './what_to_do_next';
import { CheckOutlined } from '@ant-design/icons';
import globalHelper from '../../../helpers/functions/global.helper';

const PaymentResultSuccess = ({ticket}) => {
    const ticket_count = ticket?.ticket_users?.reduce((a, b) => (a + b?.count), 0);
    return (
        <>
            <div className='mobile-padding-24' style={{ width:'100%'}}>
                <div style={{position:'relative'}}>
                    <EntranceMotion initialY={-100} duration={0.5}>
                        <Badge count={
                                <div>
                                    <EntranceMotion initialY={-50} duration={0.5} delay={0.2}>
                                        <Tag
                                        color={'green'} 
                                        style={{
                                            border:'none', 
                                            textAlign:'center', 
                                            borderRadius:'50%', 
                                            width:40, 
                                            height:40, 
                                            display:'flex', 
                                            justifyContent:'center',
                                            alignItems:'center',
                                        }}
                                        >
                                            <CheckOutlined style={{fontSize:20}} />
                                        </Tag>
                                </EntranceMotion>
                                </div>
                        }
                        offset={[-16, 105]}
                        >
                            <Image src='/cibanners/success-ticket.png' style={{maxWidth: (globalHelper?.isMobileScreen() ? 120 : 140)}} preview={false}/>
                        </Badge>
                    </EntranceMotion>
                    
                </div>
                <div style={{marginTop:12}}>
                    {/* <Lottie animationData={successJson} loop={false} style={{ height : 400, width: '100%', position: 'absolute', }}/> */}
                    <EntranceMotion initialY={-100} duration={0.5} delay={0.1}>
                        <div style={{width:'fit-content', margin: (globalHelper?.isMobileScreen() ? 'start' : 'auto'), textAlign:'center'}}>
                            <div>
                                <span className='ticket-label ticket-title h1' style={{fontWeight:'bold',}}>
                                    Transaction Successful!
                                </span>
                            </div>

                            <Space direction='vertical' size={8}>
                                <div>
                                    <span style={{fontWeight:500, color : 'var(--secondary-text-color)'}}>
                                        Your ticket to {ticket?.park?.name} have been sent to {ticket?.email}.
                                    </span>
                                </div>
                            </Space>
                        </div>
                    </EntranceMotion>

                    <div style={{marginTop:12,}}>
                        <Space wrap style={{justifyContent:'center'}}>
                            <div>
                                <EntranceMotion delay={0.1}>
                                    <Tag color='var(--secondary-button-color)' style={{color:'var(--secondary-text-color)'}}>
                                        {`Transaction No: ${ticket?.unique_no}${ticket?.salt ?? ''}`}
                                    </Tag>
                                </EntranceMotion>
                            </div>
                            <div>
                                <EntranceMotion delay={0.2}>
                                    <Tag color='var(--secondary-button-color)' style={{color:'var(--secondary-text-color)'}}>
                                        {`Ticket Type: ${ticket?.ticket_type?.name}`}
                                    </Tag>
                                </EntranceMotion>
                            </div>
                            <div>
                            <EntranceMotion delay={0.3}>
                                <Tag color='var(--secondary-button-color)' style={{color:'var(--secondary-text-color)'}}>
                                    {`Ticket Count: ${ticket_count}`}
                                </Tag>
                            </EntranceMotion>
                            </div>
                            <div>
                                <EntranceMotion delay={0.4}>
                                    <Tag color='var(--secondary-button-color)' style={{color:'var(--secondary-text-color)'}}>
                                        {
                                            ticket?.is_open_date ?
                                            (
                                                <>
                                                    {`Visit Date: Open Date`}
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    {`Visit Date: ${ticket?.visit_date}`}
                                                </>
                                            )
                                        }
                                        
                                    </Tag>
                                </EntranceMotion>
                            </div>
                            <div>
                                {
                                    ticket?.preference &&
                                    (
                                        <>
                                            <EntranceMotion delay={0.5}>
                                                <Tag color='var(--secondary-button-color)' style={{color:'var(--secondary-text-color)'}}>
                                                    {`Visit Time: ${ticket?.preference?.preference_group ? (`${ticket?.preference?.preference_group?.name} - `) : ''} ${ticket?.preference?.name}`}
                                                </Tag>
                                            </EntranceMotion>
                                        </>
                                    )
                                }
                            </div>
                        </Space>
                    </div>

                    <Divider />
                    <div>
                        <span style={{fontWeight:500, color : 'var(--secondary-text-color)'}}>
                            We use Facial AI for check-ins, remember to upload your selfies with the link provided in your email.
                        </span>
                    </div>

                    
                    <div style={{marginTop:24}}>
                        <WhatToDoNext ticket={ticket} />
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default PaymentResultSuccess;