import { message, notification } from 'antd';
import axios from 'axios';
import React, { useEffect } from 'react';
import MasterLayout from './components/master_layout';
import './layout.css';
import globalHelper from '../helpers/functions/global.helper';
import parkService from '../services/config/park.service';

const LayoutIndex = ({children}) => {
    //auto error message
    axios.interceptors.response.use(function (response) {
    // Do something with response data
        return response;
        }, function (error) {
        if(error?.response){
            const err_message = (error?.response?.data?.message);
            if(401 === error.response.status){
            //auth error
            }
            else if(422 === error.response.status){
            notification.error({
                message : err_message,
                duration : 5,
            })
            return Promise.reject(error);
            }
            else{
            // Do something with response error
            // 500
            if(!(error?.request?.responseURL?.includes('fc/recognize')) && !(error?.response?.data?.message === 'Faces not found')){
                notification.error({
                    message : err_message,
                    duration : 5,
                })
            }
            
            return Promise.reject(error);
            }
        }else{
            // Do something with response error
            notification.error({
                message : "Something went wrong, please contact our support",
                duration : 5,
            })
            return Promise.reject(error);
        }
    });

    const initializeApp = async () => {
        //button main color
        const is_main_color_dark = globalHelper?.isColorDark(process.env.REACT_APP_COLOR);
        const lighten_main_color = (globalHelper.hexToRgba(process.env.REACT_APP_COLOR, 0.05));

        // Set the value of the CSS variable
        document.documentElement.style.setProperty('--main-color', process.env.REACT_APP_COLOR);
        document.documentElement.style.setProperty('--main-text-color', is_main_color_dark ? "#fff" : "#000");
        document.documentElement.style.setProperty('--lighten-main-color', lighten_main_color);

        //get parks
        const park_code = process.env.REACT_APP_PARK_CODE;
        const parkResponse = await parkService?.getPark({ park_code : park_code, is_server_maintenance : false, });
        
        axios.defaults.headers.common['park_id'] = parkResponse?.parks?.park_id;
    }

    useEffect(() => {
        initializeApp();
    }, [])


    return (
        <>
            <MasterLayout>
                {children}
            </MasterLayout>
        </>
    );
}

export default LayoutIndex;