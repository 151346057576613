import React, { useEffect } from 'react';
import OpenDatePurchaseLayout from './components/open_date_purchase_layout';
import { useDispatch } from 'react-redux';
import { reset_purchase_detail } from '../../actions/purchase.action';

const OpenDateIndex = () => {
    const park_code = process.env.REACT_APP_PARK_CODE;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            //reset
            dispatch(reset_purchase_detail());
            //clear localstorage
            localStorage.removeItem('purchase_item');
        }
    }, [])
    
    return (
        <>
            <OpenDatePurchaseLayout park_code={park_code} />
        </>
    );
}

export default OpenDateIndex;