import { Button, Col, Row, Space } from 'antd';
import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import ResultItem from './result_item';
import SubResultItem from './sub_result_item';
import { Link } from 'react-router-dom';

const TicketInfo = ({ ticket }) => {
    const ticket_count = (ticket?.ticket_users?.reduce((a, b) => a + b.count, 0));
    return (
        <>
            <div>
                <Row gutter={[20, 20]}>
                    <Col xs={24} sm={12} md={8} span={8}>
                        <EntranceMotion initialY={-50} duration={0.5}>
                            <ResultItem icon={'ticket.svg'} title="Ticket" value={
                                <>
                                    <div>
                                        <Space direction='vertical' style={{width:'100%'}} size={12}>
                                            <SubResultItem title="Ticket Number" value={ticket?.unique_no} />
                                            <SubResultItem title="Ticket Type" value={ticket?.ticket_type?.name} />
                                            <SubResultItem title="Ticket Count" value={ticket_count} />
                                        </Space>
                                    </div>
                                </>
                            } />
                        </EntranceMotion>
                    </Col>

                    <Col xs={24} sm={12} md={8} span={8}>
                        <EntranceMotion initialY={-50} duration={0.5} delay={0.1}>
                            <ResultItem icon={'calendar.svg'} title={'Visit Date'} value={(
                                <>
                                    <Space direction='vertical'>
                                        <div>
                                            {ticket?.is_open_date ? "Open Date" : ticket?.visit_date}
                                        </div>
                                        <div>
                                            <Link to={`/date/change/${ticket?.unique_no}`}>
                                                <Button
                                                type='text'
                                                style={{background:'var(--secondary-button-color)'}}
                                                >
                                                    <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>I want to change date</span>
                                                </Button>
                                                
                                            </Link>
                                        </div>
                                    </Space>
                                </>
                            )} />
                        </EntranceMotion>
                    </Col>

                    <Col xs={24} sm={12} md={8} span={8}>
                        <EntranceMotion initialY={-50} duration={0.5} delay={0.2}>
                            <ResultItem icon={'location-pin.svg'} title={'Address'} value={ticket?.park.address} />
                        </EntranceMotion>
                    </Col>
                </Row>
                    
                    
                   
            </div>
        </>
    );
}

export default TicketInfo;