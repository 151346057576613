export const set_purchase_error = ({visit_date, email, liability_waiver_checked, ticket_users, preference, name,}) => {
    return {
        type : 'SET_PURCHASE_ERROR',
        visit_date,
        email,
        liability_waiver_checked,
        ticket_users,
        preference,
        name,
    };
}