const initialState = {
    visit_date : false,
    amount : false,
    name : false,
    email : false,

    liability_waiver_checked : false,

    // more checking
    ticket_users : false,
    preference : false,
}

const purchaseErrorReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_PURCHASE_ERROR':
            const error_state = {
                ...state,
                visit_date : action.visit_date ?? state?.visit_date,
                name : action?.name ?? state?.name,
                email : action.email ?? state?.email,
                liability_waiver_checked : action?.liability_waiver_checked ?? state?.liability_waiver_checked,
                ticket_users : action?.ticket_users ?? state?.ticket_users,
                preference : action?.preference ?? state?.preference,
            }
            return error_state;
        default :
            return state;
    }
}

export default purchaseErrorReducer;