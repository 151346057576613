import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import TicketNoSearch from './components/ticket_no_search';

const ChangeDateSearch = () => {
    const park_code = process.env.REACT_APP_PARK_CODE;
    return (
        <>
            <ContainerComponent span={12} top={60} bottom={20}>
                <TicketNoSearch park_code={park_code} /> 
            </ContainerComponent>
        </>
    );
}

export default ChangeDateSearch;