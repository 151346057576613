import React, { useEffect, useState } from 'react';
import dateService from '../../../services/ticketing/date.service';
import { LoadingOutlined } from '@ant-design/icons';
import PaymentDateResultSuccess from './payment_date_result_success';
import PaymentDateResultFail from './payment_date_result_fail';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';

const PaymentDateLayout = ({running_number}) => {
    const [state, setState] = useState({
        loading : false,
        date_change : null,
    })

    const fetchDateChange = async () => {
        setState(state => ({...state, loading : true}));
        const dateChangeResponse = await dateService.fetchDateChange({
            running_number,
        });

        setState(state => ({...state, loading: false, date_change : dateChangeResponse?.date_changes}))
        
    }

    useEffect(() => {
        fetchDateChange();
    }, []);

    return (
        <>
            {
                state?.loading ? 
                (
                    <>
                        <div style={{padding : 24}}>
                            <LoadingOutlined style={{fontSize:20}} />
                        </div>
                    </>
                )
                :
                (
                    <>
                        {
                            state?.date_change ?
                            (
                                <>
                                    {
                                        state?.date_change?.payment_status?.is_payment_complete ?
                                        (
                                            <>
                                                <PaymentDateResultSuccess date_change={state?.date_change}/>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <PaymentDateResultFail date_change={state?.date_change} />
                                            </>
                                        )
                                    }
                                    
                                </>
                            )
                            :
                            (
                                <>
                                    <SimpleNotFoundComponent
                                    title={`Date Change Detail Not Found`}
                                    description={`Date Change Transaction ${running_number} not found`}
                                    isBack
                                    />
                                </>
                            )
                        }
                        
                    </>
                )
            }
        </>
    );
}

export default PaymentDateLayout;