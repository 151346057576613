import React, { useEffect, useState } from 'react';
import PurchaseTicketDatePicker from '../../purchase/components/purchase_ticket_date_picker';
import { useDispatch, useSelector } from 'react-redux';
import parkService from '../../../services/config/park.service';
import { set_visit_date } from '../../../actions/date.action';
import dayjs from 'dayjs';
import { Calendar } from 'antd';
import ResultItem from '../../ticket/components/result_item';

const DateSelect = ({park}) => {
    const dispatch = useDispatch();
    const dateRedux = useSelector(state => state.date);
    const [state, setState] = useState({
        loading : true,
        park : null,
    });
    const changeDate = (ds, date) => {
        const new_date = dayjs(ds).format('YYYY-MM-DD');
        dispatch(set_visit_date(new_date));
    }

    const fetchPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService.getPark({
            park_code : park?.park_code
        });

        setState(state => ({...state, loading : false, park : parkResponse?.parks}));
    }

    useEffect(() => {
        fetchPark();
    }, [])

    return (
        <>
            <div style={{padding:'24px 0'}}>
                <ResultItem icon={'calendar.svg'} title={
                    <>
                        <div style={{textAlign:'start'}}>
                            <span>
                                Select Date
                            </span>
                        </div>
                    </>
                } />
            {/* <Calendar style={{maxHeight:200}} /> */}
            <PurchaseTicketDatePicker is_screen changeDate={changeDate} park={state?.park}
            {...dateRedux?.visit_date && ({ defaultValue : dayjs(dateRedux.visit_date) }) } />
            </div>
        </>
    );
}

export default DateSelect;