import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AddOnImage = ({ image }) => {

    const [state, setState] = useState({
        loading : false,
    })

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <LazyLoadImage
                src={image}
                beforeLoad={() => setState(state => ({...state, loading : true, }))}
                afterLoad={() => setState(state => ({...state, loading : false, }))}
                effect='blur'
                style={{height:80, width:80, objectFit:'cover',  borderRadius:8, }}
                />
            </Spin>
        </>
    );
}

export default AddOnImage;