import React, { useState, useEffect } from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import { useDispatch, useSelector } from 'react-redux';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Button, Drawer, Space, Spin, Tag, notification } from 'antd';
import MobilePurchaseSummary from './mobile_purchase_summary';
import { motion } from 'framer-motion';
import ticketService from '../../../services/ticketing/ticket.service';
import { set_purchase_error } from '../../../actions/purchase_error.action';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import MobileCartDetail from './mobile_cart_detail';
import numberHelper from '../../../helpers/functions/number.helper';
import { reset_purchase_detail } from '../../../actions/purchase.action';
import DateCalendar from '../../face_config/components/date_calendar';
import SummaryDateCalendar from '../components/summary_date_calendar';
import moment from 'moment';

const MobileBottomCheckout = ({park}) => {
    const { innerWidth } = window;
    const dispatch = useDispatch();

    const [amount, setAmount] = useState({
        loading : false,

        // summary amount
        discount_ticket_users : [],
        discount_add_ons : [],
        sub_total : 0,
        discount_amount : 0,
        total_amount : 0,
        taxes : [],
    })

    const purchaseRedux = useSelector(state => state?.purchase);
    const ticketRedux = useSelector(state => state?.ticket);
    const ticket_price = (purchaseRedux?.ticket_users?.map(x => ((x.price ?? 0) * x.quantity))?.reduce((a, b) => a + b, 0));
    const add_on_price = (purchaseRedux?.add_ons?.map(x => ((x?.price ?? 0) * x?.quantity))?.reduce((a, b) => a + b, 0));
    const price =  ticket_price + add_on_price;

    const currency = ticketRedux?.park?.currency?.symbol;

    // ticket type for getting date range
    const ticket_types = park?.ticket_types?.find(x => x.ticket_type_id === purchaseRedux?.ticket_type_id);

    // preference
    const preference = ticketRedux?.preference_groups?.flatMap(x => x?.preferences)?.find(x => x.preference_id == purchaseRedux?.preference_id);

    // capacity
    const is_remaining_capacity = (!ticketRedux?.is_capacity || (ticketRedux?.is_capacity && ticketRedux?.remaining_capacity > 0));
    // disabled button either is no ticket selected or no remaining capacity
    const is_purchaseable = (is_remaining_capacity);

    const [state, setState] = useState({
        rule_loading : false,
        is_drawer : false,
    })

    const checkTicketRules = async () => {
        setState(state => ({...state, rule_loading : true}));
        const { ticket_type_id, park_id, ticket_users, visit_date, email, liability_waiver_checked, preference_id, name, } = purchaseRedux;
        const { special_link } = ticketRedux;
        
        const checkRuleResponse = await ticketService.checkRules({
            ticket_type_id, park_id, ticket_users, visit_date : globalHelper.parseDate(visit_date), email, special_link_id : special_link?.special_link_id,
            liability_waiver_checked, preference_id, name,
        }).catch(err => {
            console.error(err);
        });

        //if there is error
        if(checkRuleResponse?.error){
            const error = checkRuleResponse?.error[0];
            const { message, type } = error ?? {};
            notification.warning({
                message : 'Oops',
                description : message,
            });

            setState(state => ({...state, rule_loading : false}));
            dispatch(set_purchase_error({
                [type] : true,
            }))
        }else{
            setState(state => ({...state, rule_loading : false, is_drawer : true}));
        }
    }

    const initializeTotalPrice = async () => {
        //make sure final price is same with price calculated in backend
        const { ticket_users, add_ons, promotion_code, park_id, visit_date, } = purchaseRedux;
        if(park_id){ //park id is compulsory to prevent duplicates request call
            setAmount(amount => ({...amount, loading : true, }));
            const calculatePricingResponse = await ticketService?.calculateTicketPricing({
                park_id,
                ticket_users,
                add_ons,
                visit_date : globalHelper?.parseDate(visit_date),
                promo_code : promotion_code?.promo_code,
            });
    
            const { calculate_promotion_amount, sub_total, total_amount, total_ticket_amount, total_add_on_amount, taxes, } = calculatePricingResponse;
            const { total_discount_amount : discount_amount, ticket_user_amount, add_on_amount,  } = calculate_promotion_amount;
            setAmount(amount => ({...amount, loading : false, discount_ticket_users : ticket_user_amount, discount_add_ons : add_on_amount, sub_total, total_amount, discount_amount, taxes,  }));
        }
    }

    const startOver = (e) => {
        e.stopPropagation();
        dispatch(reset_purchase_detail(park?.park_id));
        localStorage?.removeItem('purchase_item');
    }

    //setting price
    useEffect(() => {
        initializeTotalPrice();
    }, [purchaseRedux?.visit_date, JSON.stringify(purchaseRedux?.ticket_users), JSON.stringify(purchaseRedux?.add_ons), purchaseRedux?.promotion_code,]) //when price and promotion code is changed, re-calculate final price

    return (
        <>
            {
                globalHelper?.isMobileScreen() &&
                <div>
                    <div style={{marginTop:64}} />
                    {
                        // only show when capacity is avaiable
                        is_purchaseable &&
                        (
                            <>
                                <div
                                className='footer-checkout-button'
                                onClick={checkTicketRules}
                                >
                                    <EntranceMotion initialY={100} duration={1}>
                                        <Spin spinning={state?.rule_loading || amount?.loading} indicator={<LoadingOutlined style={{color:'var(--main-text-color)'}} />}>
                                            <div style={{padding:'.75rem', display:'flex', alignItems:'center', color : 'var(--main-text-color)'}}>
                                                <div>
                                                    <Button
                                                    className='reset-button'
                                                    type='text'
                                                    onClick={(e) => startOver(e)}
                                                    style={{background : 'rgba(0, 0, 0, 0.06)'}}
                                                    >
                                                        <span style={{ color : 'var(--main-text-color)' }}>Reset</span>
                                                    </Button>
                                                </div>
                                                <div style={{ marginLeft:'auto' }}>
                                                    <Space size={12} align='center'>
                                                        <div>
                                                            <span className='ticket-label' style={{fontSize:20, fontWeight:500}}>
                                                                {`${currency}${numberHelper?.commaNumber(amount?.total_amount)}`}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className='ticket-label' style={{fontSize:14, fontWeight:500, opacity:0.8, }}>Continue </span>
                                                        </div>
                                                    </Space>
                                                </div>
                                            </div>
                                        </Spin>
                                    </EntranceMotion>
                                </div>
                            </>
                        )
                    }

                    {/* Cart */}
                    <Drawer
                    className='purchase-summary-drawer'
                    open={state?.is_drawer}
                    width={innerWidth}
                    height={'100%'}
                    placement="bottom"
                    closeIcon={(
                        <>
                            <CloseOutlined style={{ color : (park?.cover_image ? '#fff' : 'var(--secondary-text-color)'), }} />
                        </>
                    )}
                    styles={{ body : { padding : 0, }, ...(park?.cover_image && ({ header : { backgroundImage : `url(${park?.cover_image})`, backgroundPosition : 'center', backgroundSize : 'cover', backgroundRepeat :'no-repeat', padding : 0, } }) )}}
                    title={
                        (
                            <>
                                <div {...park?.cover_image && ({ className : 'card-overlay', style : { padding : '16px 24px', } })}>
                                    <Space align='start' size={20}>
                                        <SummaryDateCalendar date={globalHelper.parseDate(purchaseRedux?.visit_date)} />
                                        <div>
                                            <div>
                                                <span style={{fontSize:14, color : (park?.cover_image ? '#fff' : 'var(--secondary-text-color)'), fontWeight:'normal'}}>
                                                    {`Ticket Type : ${park?.ticket_types?.find(x => x.ticket_type_id === purchaseRedux?.ticket_type_id)?.name}`}
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:14, color : (park?.cover_image ? '#fff' : 'var(--secondary-text-color)'), fontWeight:'normal'}}>
                                                    {`Visit Date : ${(`${purchaseRedux?.visit_date} ${ticket_types?.ParkApplicableTicketType?.date_range > 0 ? `to ${moment(globalHelper.parseDate(purchaseRedux?.visit_date))?.add((ticket_types?.ParkApplicableTicketType?.date_range ?? 0), 'days')?.format("DD/MM/YYYY")}` : ''}` ?? 'N/A')}`}
                                                </span>
                                            </div>
                                            {
                                                preference &&
                                                (
                                                    <>
                                                        <div>
                                                            <Tag style={{border : 'none', color : 'var(--main-color)', fontWeight:'normal', }} color={'var(--lighten-main-color)'}>
                                                                {`${preference?.preference_group ? (`${preference?.preference_group?.name} - `) : ''} ${(preference?.name)}`}
                                                            </Tag>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Space>
                                </div>
                            </>
                        )
                    }
                    onClose={() => setState(state => ({...state, is_drawer : false,}))}
                    >
                        <motion.div
                        initial="closed"
                        animate={state?.is_drawer ? "open" : "closed"}
                        style={{height : '100%',}}
                        variants={{
                            open: { opacity: 1, y: 0, },
                            closed: { opacity: 0, y: '-100%', },
                        }}
                        transition={{
                            duration : 1, type: "spring", stiffness: 260, damping: 20
                        }}
                        >
                            <div style={{display : 'flex', flexDirection : 'column', height : 'inherit',}}>
                                {/* <MobilePurchaseSummary park={park} ticket_price={ticket_price} add_on_price={add_on_price} price={price} is_drawer={state?.is_drawer} /> */}
                                <MobilePurchaseSummary park={park} amount={amount} />
                            </div>
                        </motion.div>
                    </Drawer>
                </div>
            }
        </>
    );
}

export default MobileBottomCheckout;