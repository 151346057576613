import { Space } from 'antd';
import moment from 'moment';
import React from 'react';

const DateCalendar = ({date, is_open_date, }) => {
    return (
        <>
            <div style={{height: 60, width: 60, background:'#fff', borderRadius:6}}>
                <div style={{background:'var(--main-color)', height: 6, borderRadius:'6px 6px 0 0'}} />
                <div style={{
                    height:'100%', 
                    padding:'6px 4px', 
                    textAlign:'center', 
                    border : '1px solid #cbd5e0', 
                    borderTop:'none', 
                    borderBottomLeftRadius:6, 
                    borderBottomRightRadius:6
                }}>
                    {
                        is_open_date ?
                        (
                            <>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)', fontWeight:500, marginTop:4, display:'flex'}}>Open Date</span>
                                </div>
                            </>
                        )
                        :
                        (
                            <>
                                <Space direction='vertical' size={0}>
                                    <div>
                                        <span className='header-font' style={{fontSize:24, lineHeight:1, color:'#434343'}}>{moment(date)?.format("DD")}</span>
                                    </div>
                                    <div>
                                        <span style={{color:'#434343'}}>{moment(date)?.format("MMM YY")}</span>
                                    </div>
                                </Space>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default DateCalendar;