import { LoadingOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Space, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import globalHelper from '../../../helpers/functions/global.helper';
import numberHelper from '../../../helpers/functions/number.helper';
import PurchaseTaxes from '../../purchase/components/purchase_taxes';
import PromoCode from '../../purchase/components/promo_code';
import OrderTicketUserList from '../../purchase/components/order_ticket_user_list';
import { price_calculating, set_remaining_promo_tickets } from '../../../actions/ticket.action';
import ticketService from '../../../services/ticketing/ticket.service';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const OpenDatePurchaseSummary = ({ park, setPurchaseAmount = () => {}, }) => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state?.purchase);

    const cardRef = useRef();

    // memoized
    const currency = park?.currency?.symbol;
    const contentHeight = cardRef?.current?.clientHeight;
    const promotion_code = purchaseRedux?.promotion_code;
    const is_stack = promotion_code?.is_stack;
    const ticket_price = (purchaseRedux?.ticket_users?.map(x => (( (is_stack ? x.price : x?.actual_price) ?? 0) * x.quantity))?.reduce((a, b) => a + b, 0));
    const add_on_price = (purchaseRedux?.add_ons?.map(x => ((x?.price ?? 0) * x?.quantity))?.reduce((a, b) => a + b, 0));
    const price =  ticket_price + add_on_price;

    const is_item_length = (purchaseRedux?.ticket_users?.length > 0 || purchaseRedux?.add_ons?.length > 0);

    const [state, setState] = useState({
        loading : false,

        discount_ticket_users : [],
        discount_add_ons : [],
        sub_total : 0,
        discount_amount : 0,
        total_amount : 0,
        taxes : [],
    });

    const initializeTotalPrice = async () => {
        //make sure final price is same with price calculated in backend
        const { ticket_users, add_ons, promotion_code, park_id, open_date = false, } = purchaseRedux;
        if(park_id){ //park id is compulsory to prevent duplicates request call
            dispatch(price_calculating(true));
            setState(state => ({...state, loading : true, }));
            const calculatePricingResponse = await ticketService?.calculateTicketPricing({
                park_id,
                ticket_users,
                add_ons,
                // visit_date : globalHelper?.parseDate(visit_date),
                promo_code : promotion_code?.promo_code,
                open_date,
            });
    
            const { calculate_promotion_amount, sub_total, total_amount, total_ticket_amount, total_add_on_amount, purchaseable_promo_ticket_count, taxes, } = calculatePricingResponse;

            const { total_discount_amount : discount_amount, ticket_user_amount, add_on_amount,  } = calculate_promotion_amount;
            setState(state => ({...state, loading : false, discount_ticket_users : ticket_user_amount, discount_add_ons : add_on_amount, sub_total, total_amount, discount_amount, taxes, }));
            dispatch(set_remaining_promo_tickets(purchaseable_promo_ticket_count));
        }
    }

    useEffect(() => {
        initializeTotalPrice();
    }, [JSON.stringify(purchaseRedux?.ticket_users), JSON.stringify(purchaseRedux?.add_ons), purchaseRedux?.promotion_code]);

    useEffect(() => {
        setPurchaseAmount?.({
            total_amount : state?.total_amount,
            sub_total : state?.sub_total,
            taxes : state?.taxes,
            discount_amount : state?.discount_amount,
        });
    }, [state])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div
                // style={{ height : `calc(${globalHelper?.isMobileScreen() ? '60vh' : '75vh'} - ${footerRef?.current?.clientHeight}px)`, }}
                >
                    <div
                    style={{borderColor:'#cbd5e0', height:'100%', borderWidth: 1.5}}
                    ref={cardRef}
                    >
                        <div style={{height:'inherit',}}>
                            <div
                            className='summary-cart-items scroll-item' 
                            style={{
                                overflowY:'auto',
                                flexGrow : 1, 
                                maxHeight: 'calc(100%)',
                                height : (is_item_length ? 'auto' : '100%')
                            }}
                            >
                                <div>
                                {
                                    is_item_length ?
                                    (
                                        <>
                                            <OrderTicketUserList discount_ticket_users={state?.discount_ticket_users} />
                                            {/* <OrderAddOnList discount_add_ons={state?.discount_add_ons} /> */}
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div style={{display:'flex', height: 'inherit', alignItems:'center', justifyContent:'center',}}>
                                                <span style={{color:'#6f7287'}}>No Item Added</span>
                                            </div>
                                        </>
                                    )
                                }
                                </div>
                            </div>
                        </div>

                        {/* <div>
                            <div ref={footerRef} className='purchase-modal-footer' style={{ textAlign: 'left', padding: 0, position: 'initial', }}>
                                <div style={{ padding: '12px 24px', height: 70, display: 'flex', alignItems: 'center', }}>
                                    <PromoCode subtotal={0} size={'large'} />
                                </div>
                                <Divider style={{ margin: 0 }} />
                                <div style={{ padding: '12px 24px', }}>
                                    <div>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <div>
                                                <span style={{ color: 'var(--secondary-text-color)' }}>Sub Total</span>
                                            </div>

                                            <div style={{ marginLeft: 'auto' }}>
                                                <span className='ticket-label' style={{ fontSize: 14, }}>
                                                    {`${currency}${numberHelper.commaNumber(state?.sub_total)}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <div>
                                                <span style={{ color: 'var(--secondary-text-color)' }}>Discount</span>
                                            </div>

                                            <div style={{ marginLeft: 'auto' }}>
                                                <span className='ticket-label' style={{ fontSize: 14, color: 'var(--main-color)' }}>
                                                    {`-${currency}${numberHelper.commaNumber(state?.discount_amount)}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <PurchaseTaxes park_taxes={park?.park_taxes} taxes={state?.taxes} />
                                    </div>

                                    <div style={{ display: 'flex', }}>
                                        <div>
                                            <span style={{ color: 'var(--secondary-text-color)' }}>Total</span>
                                        </div>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <span className='ticket-label' style={{ fontSize: 20, fontWeight: 500 }}>
                                                {`${currency}${numberHelper.commaNumber(state?.total_amount)}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default OpenDatePurchaseSummary;