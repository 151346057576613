import { Space } from 'antd';
import moment from 'moment';
import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const SummaryDateCalendar = ({ date }) => {
    return (
        <>
            <div style={{ height : 'auto', width: 'auto', background:'#fff', borderRadius:6}}>
                <div style={{background:'var(--main-color)', height: 6, borderRadius:'6px 6px 0 0'}} />
                <div style={{
                    height:'100%', 
                    padding:'4px', 
                    textAlign:'center', 
                    border : '1px solid #cbd5e0', 
                    borderTop:'none', 
                    borderBottomLeftRadius:6, 
                    borderBottomRightRadius:6,
                }}>
                    <div style={{ width : 50, height : 50, }}>
                    {
                        date ?
                        (
                            <>
                                <EntranceMotion style={{ height : '100%', width : '100%', }}>
                                    <div style={{display : 'flex', alignItems : 'center', justifyContent : 'center', height : 'inherit', }}>
                                        <div style={{fontWeight:'normal',}}>
                                            <div style={{lineHeight:1,}}>
                                                <span className='header-font' style={{fontSize:14,  color:'#434343', fontWeight:500,}}>{moment(date)?.format("DD")}</span>
                                            </div>
                                            <div style={{lineHeight:1, marginTop:4,}}>
                                                <span style={{color:'#434343', fontSize:12,}}>{moment(date)?.format("MMM YY")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </EntranceMotion>
                            </>
                        )
                        :
                        (
                            <div style={{display : 'flex', alignItems : 'center', justifyContent : 'center', height : 'inherit', }}>
                                <span className='header-font' style={{fontSize:14, lineHeight:1, color:'#434343'}}>{'N/A'}</span>
                            </div>
                        )
                    }
                    </div>
                </div>
            </div>
        </>
    );
}

export default SummaryDateCalendar;