import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import TicketLayout from './components/ticket_layout';
import { useParams } from 'react-router-dom';

const TicketIndex = () => {
    const { ticket_id } = useParams();
    return (
        <>
            <ContainerComponent span={16} top={20} bottom={20}>
                <TicketLayout ticket_unique_no={ticket_id} />
            </ContainerComponent>
        </>
    );
}

export default TicketIndex;