import React from 'react';
import IconWrapper from '../../../helpers/components/icon_wrapper';

const ResultItem = ({icon, title, value}) => {
    return (
        <>
            <div>
                <div style={{width:'100%', display:'flex', alignItems:'center', gap:12}}>
                    <div>
                        <IconWrapper icon={icon} />
                    </div>
                    <div style={{width:'100%'}}>
                        <div>
                            <span style={{fontSize: 18, fontWeight:500, }}>{title}</span>
                        </div>
                        
                    </div>
                </div>
                
                <div style={{marginTop:12}}>
                    <span style={{color:'#6f7287', fontSize:14}}>
                        {value}
                    </span>
                </div>
            </div>
        </>
    );
}

export default ResultItem;