import React from 'react';
import { useParams } from 'react-router-dom';
import DateLayout from './components/date_layout';
import ContainerComponent from '../../helpers/components/container.component';

const ChangeDateIndex = () => {
    const { token } = useParams();
    return (
        <>
            <ContainerComponent span={16} top={20} bottom={20}>
                <DateLayout token={token} />
            </ContainerComponent>
        </>
    );
}

export default ChangeDateIndex;