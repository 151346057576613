import React from 'react';
import LayoutIndex from '../layouts';
import AppRouter from './app.router';

const AuthRouter = () => {
    return (
        <>
            <LayoutIndex>
                <AppRouter />
            </LayoutIndex>
        </>
    );
}

export default AuthRouter;