import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function validatePromoCode(params){
    // const queryString = serviceHelper.queryString(query);
    const parkResponse = await axios.post(`config/promotion_code/validate`, params, { withCredentials : true });
    return parkResponse?.data;
}

export default {
    validatePromoCode,
}