const initialState = {
    visit_date : false,
    preference : null,
    final_price : null,
    park : null,
}

const dateReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_VISIT_DATE':
            return {...state, visit_date : action.visit_date};
        case 'SET_PREFERENCE' : 
            return {...state, preference : action.preference};
        case 'SET_FINAL_PRICE':
            return {...state, final_price : action?.final_price};
        case 'SET_DATE_PARK':
            return {...state, park : action?.park};
        default :
            return state;
    }
}

export default dateReducer;