import { combineReducers } from 'redux';
import ticketReducer from './ticket.reducer';
import recognitionReducer from './recognition.reducer';
import purchaseReducer from './purchase.reducer';
import purchaseErrorReducer from './purchase_error.reducer';
import dateReducer from './date.reducer';

const allReducers = combineReducers({
    ticket : ticketReducer,
    recognition : recognitionReducer,
    purchase : purchaseReducer,
    purchase_error : purchaseErrorReducer,
    date : dateReducer,
});

const rootReducer = (state, action) => {
    return allReducers(state, action);
}

export default rootReducer;