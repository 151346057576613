import { Alert } from 'antd';
import React, { useMemo } from 'react'
import ReactQuill from 'react-quill-v2.0';
import { useSelector } from 'react-redux';

const TopBanner = ({ height, bannerQuill, }) => {
    return (
        <>
            <div
            style={{
                position : 'sticky',
                top : 0,
                zIndex : 100,
            }}>
                <Alert
                style={{ borderRadius : 0, height: height, }}
                message={(
                    <>
                        <ReactQuill
                        readOnly
                        className='ticket-label ticket-title h1 banner-quill'
                        theme={null}
                        value={bannerQuill}
                        style={{ fontSize:12, color:'var(--secondary-text-color)', whiteSpace : 'wrap', margin : 0, }}
                        />
                    </>
                )}
                />
            </div>
        </>
    )
}

export default TopBanner;