export const upload_image = (image) => {
    return {
        type : 'UPLOAD_IMAGE',
        image
    };
}

export const set_special_link = (special_link) => {
    return {
        type : 'SET_SPECIAL_LINK',
        special_link
    };
}

export const set_liability_waiver = (liability_waiver_modal) => {
    return {
        type : 'SET_LIABILITY_WAIVER',
        liability_waiver_modal
    }
}

export const set_is_add_on = (is_add_on) => {
    return {
        type : 'SET_IS_ADD_ON',
        is_add_on,
    }
}

export const set_park = (park) => {
    return {
        type : 'SET_PARK',
        park,
    }
}

export const set_remaining_promo_tickets = (remaining_promo_tickets) => {
    return {
        type : 'SET_REMAINING_PROMO_TICKETS',
        remaining_promo_tickets,
    }
}

export const price_calculating = (loading) => {
    return {
        type : 'PRICE_CALCULATING',
        loading,
    }
}

export const set_ticket_saveable = (ticket_saveable) => {
    return {
        type : 'SET_TICKET_SAVEABLE',
        ticket_saveable,
    }
}

export const set_capacity = (is_capacity, remaining_capacity,)  => {
    if(!((remaining_capacity > 0 && is_capacity) || !is_capacity)){
        // reset purchase
        // localStorage?.removeItem("purchase_item");
    }

    return {
        type : 'SET_CAPACITY',
        is_capacity,
        remaining_capacity,
    }
}

export const set_preference = (preference_groups) => {
    return {
        type : 'SET_PREFERENCE',
        preference_groups,
    }
}