import React, { useEffect, useState } from 'react';
import ticketService from '../../../services/ticketing/ticket.service';
import DateCalendar from './date_calendar';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import TicketUserCard from './ticket_user_card';
import { Col, Pagination, Row, Space, Tag } from 'antd';
import TicketCard from '../components_bak/ticket_card';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';

const ListLayout = ({ token, page, hash, }) => {
    const pageSize = 20;
    const is_hash = (parseInt(hash) > 0);
    const history = useHistory();
    const [state, setState] = useState({
        loading : true,
        ticket_users : [],
        ticket : null,

        //filter
        page : page ? parseInt(page) : 1,
        row_count : 0,
    });

    const fetchTicket = async () => {
        const ticketResponse = await ticketService.getTicketUser({
            ...(is_hash ? ({ token : token, }) : ({ ticket_unique_no : token, })),
            page : ((state?.page - 1) ?? 0), 
            size : pageSize,
        });

        const { ticket_users, ticket } = ticketResponse;
        
        return {
            ticket_users,
            ticket,
        }
    }

    const changePage = (page) => {
        setState(state => ({ ...state, page: page }));
        
        // Replace the current URL with the updated query parameters
        window.history.replaceState({}, '', `${window.location.pathname}?page=${page}&c=1`);
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true}));
        // check by token or ticket user
        // to control back on existing ticket
        const { ticket_users, ticket, } = await fetchTicket();
        if(!is_hash && ticket){
            // existing ticket before 17/5/2024
            // need to redirect to hash
            history.push(`/ticket/face/${ticket?.token}?c=1`);
        }

        setState(state => ({...state, ticket_users: ticket_users?.rows, ticket : ticket, loading : false, row_count : ticket_users?.count}));
    }

    useEffect(() => {
        initialize();
    }, [state?.page])

    return (
        <>
            <div className='mobile-padding-12'>
                {
                    state?.loading ? 
                    (
                        <>
                            <div>
                                <LoadingOutlined style={{fontSize:20}} />
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            {
                                state?.ticket ?
                                (
                                    <>
                                        <div style={{ textAlign:'left'}}>
                                            <div>
                                                <span
                                                className='ticket-label ticket-title h1'
                                                style={{
                                                    fontWeight:'bold',
                                                }}
                                                >
                                                    {state?.ticket?.park?.name}
                                                </span>
                                            </div>
                                            <div style={{marginTop:8}}>
                                                <Space align='start'>
                                                    <div>
                                                        <DateCalendar is_open_date={state?.ticket?.is_open_date} date={state?.ticket?.visit_date} />
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span style={{ fontWeight:500}}>
                                                                <Space wrap size={2}>
                                                                    <span>Transaction No:</span>
                                                                    <span>{`${state?.ticket?.unique_no}${state?.ticket?.salt ?? ''}`}</span>
                                                                </Space>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <Space wrap align='center'>
                                                                <div>
                                                                    <span style={{color:'#6f7287', fontWeight:500}}>
                                                                        <Space wrap size={2}>
                                                                            <span>Ticket Group:</span>
                                                                            <span>{state?.ticket?.ticket_type?.name}</span>
                                                                        </Space>
                                                                    </span>
                                                                </div>
                                                                {
                                                                    state?.ticket?.preference &&
                                                                    (
                                                                        <>
                                                                            <div>
                                                                                <Tag style={{border : 'none', color : 'var(--main-color)', }} color={'var(--lighten-main-color)'}>
                                                                                    {`${state?.ticket?.preference?.preference_group ? (`${state?.ticket?.preference?.preference_group?.name} - `) : ''} ${state?.ticket?.preference?.name}`}
                                                                                </Tag>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            </Space>
                                                        </div>
                                                    </div>
                                                </Space>
                                            </div>
                                        </div>

                                        <div style={{marginTop:24}}>
                                            <Row gutter={[12, 12]} justify={'start'}>
                                            {
                                                state?.ticket_users?.map((ticket_user, index) => {
                                                    return (
                                                        <Col key={index} xs={12} sm={8} md={6} span={6}>
                                                            <EntranceMotion initialY={-100} duration={0.5} delay={ index * 0.1 } style={{height:'100%'}}>
                                                                <TicketUserCard ticket_user={ticket_user} ticket={state?.ticket} />
                                                            </EntranceMotion>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            </Row>

                                            {/* pagination */}
                                            <div style={{marginTop:24}}>
                                                <Pagination 
                                                showSizeChanger={false} 
                                                pageSize={pageSize} 
                                                onChange={changePage}
                                                current={state?.page}
                                                total={state?.row_count} 
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <SimpleNotFoundComponent
                                        isBack
                                        title={`Transaction Not Found`}
                                        description={`${token} not found.`}
                                        />
                                    </>
                                )
                            }
                            
                        </>
                    )
                }
            </div>
        </>
    );
}

export default ListLayout;