import axios from 'axios';
import moment from 'moment';
import serviceHelper from '../../helpers/functions/service.helper';

async function recognizeImage({image, entrance_no, visit_date = moment().format("YYYY-MM-DD"), is_force_checkout}){
    const formData = new FormData();
    formData.append('image', image);
    formData.append('entrance_no', entrance_no);
    formData.append('visit_date', visit_date);
    formData.append('is_force_checkout', is_force_checkout);
    const recognizeResponse = await axios.post(`fc/recognize`, formData, { withCredentials : true });
    return recognizeResponse?.data;
}

async function configFace(params = {}){
    const { image, ticket_user_id, unique_no } = params;
    const formData = new FormData();
    formData.append('image', image);
    formData.append('ticket_user_id', ticket_user_id);
    formData.append('unique_no', unique_no);
    const configFaceResponse = await axios.post(`fc/config`, formData, { withCredentials : true });

    return configFaceResponse?.data;
}

async function deleteFace(params = {}){
    const { ticket_user_id } = params;
    const deleteFaceResponse = await axios.delete(`fc/ticket_user/${ticket_user_id}/face/delete`, { withCredentials : true });

    return deleteFaceResponse?.data;
}

async function getImage(image_id){
    const imageResponse = await axios.get(`fc/image/${image_id}`, { withCredentials : true });
    return imageResponse?.data;
}


export default {
    recognizeImage,
    configFace,
    deleteFace,
    getImage,
}