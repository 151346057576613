import React from 'react';
import { RWebShare } from 'react-web-share';

const ShareTicketUser = ({ park, ticket_user_no, children }) => {
    return (
        <>
            <RWebShare
            data={{
                text: `${park?.name}!`,
                url: `${park?.base_url}ticket/user/${ticket_user_no}`,
                title: `${ticket_user_no}`,
            }}
            onClick={() => console.log("shared successfully!")}
            >
                {children}
            </RWebShare>
        </>
    );
}

export default ShareTicketUser;