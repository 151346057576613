export const set_visit_date = (visit_date,) => {
    return {
        type : 'SET_VISIT_DATE',
        visit_date,
    };
}

export const set_final_price = (final_price) => {
    return {
        type : 'SET_FINAL_PRICE',
        final_price,
    }
}

export const set_date_park = (park) => {
    return {
        type : 'SET_DATE_PARK',
        park,
    }
}

export const set_preference = (preference) => {
    return {
        type : 'SET_PREFERENCE',
        preference,
    }
}