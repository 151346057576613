import React from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import { Image, Space } from 'antd';

const OpenDateAlert = () => {
    return (
        <>
            <div className='brick-background' style={{textAlign:'start', background:'var(--secondary-button-color)', borderRadius:8, marginTop:12,}}>
                <div style={{padding: 24,}}>
                    <div>
                        <span className='ticket-label ticket-title'
                        style={{
                            fontWeight:500,
                            fontSize: 20,
                            // color : 'var(--secondary-text-color)'
                        }}>
                            Open Date Channel
                        </span>
                    </div>
                    <div>
                        <span style={{color : 'var(--secondary-text-color)'}}>
                        This link allows you to purchase tickets with an open date, giving you the flexibility to visit the park on any available day.
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OpenDateAlert;