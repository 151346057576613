const initialState = {
    scanned_ticket_user : null,
    is_scanned : false,
}

const recognitionReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_SUBJECT':
            return {...state, scanned_ticket_user : action.ticket_user, is_scanned : action.is_scanned}
        default :
            return state;
    }
}

export default recognitionReducer;