import './App.less';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRouter from './routes/auth.router';
import { ConfigProvider } from 'antd';

function App() {
  return (
    <ConfigProvider
    theme={{
      token : {
        colorPrimary : process.env.REACT_APP_COLOR ?? '#FFC727',
      }
    }}
    >
      <div className="App">
        <Router>
          <AuthRouter />
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
