import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function generateDatePaymentLink(date_change_no){
    const linkResponse = await axios.post(`payment/date/url/${date_change_no}`, { withCredentials : true });
    return linkResponse?.data;
}

export default {
    generateDatePaymentLink,
}