import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getPreferences(query){
    const queryString = serviceHelper.queryString(query);
    const preferenceResponse = await axios.get(`config/park/preference?${queryString}`, { withCredentials : true });
    return preferenceResponse?.data;
}

export default {
    getPreferences,
}