import { Image } from 'antd';
import React from 'react';
import DynamicSvg from './dynamic_svg';

const IconWrapper = ({icon}) => {
    return (
        <>
            <div>
                <DynamicSvg image={`/cicons/${icon}`} size={40}/>
                {/* <Image preview={false} height={40} width={40} src={
                    
                } /> */}
            </div>
        
        </>
    );
}

export default IconWrapper;