import React from 'react';
import ResultItem from './result_item';
import SubResultItem from './sub_result_item';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Space } from 'antd';

const ContactInfo = ({ticket}) => {
    return (
        <>
            <EntranceMotion initialY={-50} duration={0.5}>
                <ResultItem icon={'profile.svg'} title="Contact Information" value={
                    <>
                        <div>
                            <Space direction='vertical' style={{width:'100%'}} size={12}>
                                <SubResultItem title="Name" value={ticket?.name ?? "Anonymous"} />
                                <SubResultItem title="Email" value={ticket?.email} />
                            </Space>
                        </div>
                    </>
                } />
            </EntranceMotion>
        </>
    );
}

export default ContactInfo;