import { Image, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import globalHelper from '../../../helpers/functions/global.helper';
import moment from 'moment';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const SpecialLink = () => {
    const ticketRedux = useSelector(state => state.ticket);
    const [state, setState] = useState({
        minutes : null,
        seconds : null,
    });


    const calculateTimeRemaining = () => {
        const interval = setInterval(() => {
            const diff = moment(ticketRedux?.special_link?.expired_datetime).diff(moment());
            if(diff <= 0){
                clearInterval(interval);
                window.location.reload();
            }else{
                var duration = moment.duration(diff);
                // var hours = Math.floor(duration.asHours());
                var minutes = String(Math.floor(duration.asMinutes()) % 60).padStart(2, "0");
                var seconds = String(Math.floor(duration.asSeconds()) % 60).padStart(2, "0");

                setState(state => ({...state, minutes, seconds,}))
            }
            
        }, 1000)
        
    }

    useEffect(() => {
        if(!ticketRedux?.special_link?.is_used){
            calculateTimeRemaining();
        }
       
    }, [])


    return (
        <>
            <div style={{textAlign:'start', background:'var(--secondary-button-color)', borderRadius:8, marginTop:12,}}>
                <div style={{padding:'24px 24px 0 24px',}}>
                    <div>
                        <span className='ticket-label ticket-title'
                        style={{
                            fontWeight:500,
                            fontSize: 20,
                            // color : 'var(--secondary-text-color)'
                        }}>
                            Special Purchase Channel
                        </span>
                    </div>
                    <div>
                        <span style={{color : 'var(--secondary-text-color)'}}>
                        This link is only valid for {ticketRedux?.special_link?.minutes} minutes. Kindly add your ticket to cart within the given time frame. Worry not, you'll still have ample of time to checkout and make payment.
                        </span>
                    </div>
                </div>
                <div className='brick-background' style={{marginTop:12, padding:'12px 24px',}}>
                    <Space size={40}>
                        <div>
                            <Image src='/cibanners/ticket-clock.png' style={{maxWidth: (globalHelper?.isMobileScreen() ? 60 : 60)}} preview={false}/>
                        </div>
                        {
                            ticketRedux?.special_link?.is_used ?
                            (
                                <>
                                    <div>
                                        <div className='ticket-label ticket-title' style={{fontSize:18, fontWeight:500, textAlign:'start', color:'var(--secondary-text-color)'}}>
                                            <span>Special Purchase Used</span>
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    <div>
                                        <Space align='start'>
                                            <EntranceMotion>
                                                <div>
                                                    <div className='ticket-label ticket-title' style={{fontSize:24, fontWeight:500, textAlign:'center'}}>
                                                        <span >{state?.minutes ?? "00"}</span>
                                                    </div>
                                                    <div>
                                                        <span style={{fontSize:12, color:'var(--secondary-text-color)',}}>Minutes</span>
                                                    </div>
                                                </div>
                                            </EntranceMotion>
                                            <EntranceMotion delay={0.1}>
                                                <div className='ticket-label ticket-title' style={{fontSize:24, fontWeight:500}}>
                                                    <span>:</span>
                                                </div>
                                            </EntranceMotion>
                                            <EntranceMotion delay={0.2}>
                                                <div>
                                                    <div className='ticket-label ticket-title' style={{fontSize:24, fontWeight:500, textAlign:'center'}}>
                                                        <span>{state?.seconds ?? "00"}</span>
                                                    </div>
                                                    <div>
                                                        <span style={{fontSize:12, color:'var(--secondary-text-color)',}}>Seconds</span>
                                                    </div>
                                                </div>
                                            </EntranceMotion>
                                        </Space>
                                    </div>
                                </>
                            )
                        }
                    </Space>
                </div>
            </div>
        </>
    );
}

export default SpecialLink;