import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import globalHelper from '../../../helpers/functions/global.helper';
import { set_liability_waiver } from '../../../actions/ticket.action';
import { set_purchase_detail } from '../../../actions/purchase.action';
import { set_purchase_error } from '../../../actions/purchase_error.action';
import OpenDateStepNoItem from './open_date_step_no_item';

const OpenDateLiabilityWaiver = ({ park, }) => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state?.purchase);
    const purchaseErrorRedux = useSelector(state => state?.purchase_error);

    const changeDetail = (key, value) => {
        if(purchaseErrorRedux[key]){
            dispatch(set_purchase_error({[key] : false,}))
        }

        dispatch(set_purchase_detail({
            [key] : value
        }))
    }

    const onLiabilityWaiverChecked = async (is_checked) => {
        changeDetail('liability_waiver_checked', is_checked);

        //open modal
        dispatch(set_liability_waiver(is_checked));
    }

    return (
        <>
            <div className='step-item' id='step_6' style={{ marginTop : 42, }}>
                <EntranceMotion initialY={-100} duration={0.5} delay={0.3}>
                    <div className='step-6' style={{ textAlign : 'start', }}>
                        <OpenDateStepNoItem text={'Liability Waiver'} image={'cibanners/step/tnc.png'} />
                        <div style={{marginTop:12,}}>
                            <div style={{padding:12, border:'1.5px solid #cbd5e0', borderColor:'#cbd5e0', borderRadius:8, ...purchaseErrorRedux?.liability_waiver_checked && ({ borderColor : '#ff4d4f', background : '#ffeded' })}}>
                                <Checkbox checked={purchaseRedux?.liability_waiver_checked} onChange={(e) => onLiabilityWaiverChecked(e?.target?.checked)}>
                                    <span>I have read this <a href={park?.tnc_link} target='_blank'>Liability Waiver</a>, fully understand its terms, understand that I have given up substantial rights by selecting this checkbox, and do so freely and voluntarily without any inducement.</span>
                                    {/* <span>I have read and agree on the <a href={park?.tnc_link} target='_blank'>Terms and Conditions</a></span> */}
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </EntranceMotion>
            </div>
        </>
    );
}

export default OpenDateLiabilityWaiver;