import React, { useEffect, useMemo } from 'react'
import TopBanner from './top_banner';
import { Layout } from 'antd';
import globalHelper from '../../helpers/functions/global.helper';
import MasterHeader from './master_header';
import { useSelector } from 'react-redux';

const { Header, } = Layout;
const TopHeader = () => {
    const bannerHeight = globalHelper?.isMobileScreen() ? 64 : 40;
    const ticketRedux = useSelector(state => state?.ticket);
    const park = ticketRedux?.park;

    const bannerQuill = useMemo(() => {
        let banner_quill;
        try{
            banner_quill = JSON.parse(park?.banner_text)
        }catch(err){

        }
        return banner_quill;
    }, [park])

    useEffect(() => {
        // set header height
        document.documentElement.style.setProperty('--header-height', `${(64 + (bannerHeight ?? 0))}px`);
    }, [])

    return (
        <>
            {
                bannerQuill && (
                    <>
                        <TopBanner height={bannerHeight} bannerQuill={bannerQuill} />
                    </>
                )
            }
            <Header
            style={{
                background : '#fff', 
                border:'1px solid #edf2f7', 
                position: 'sticky', 
                top: (bannerQuill ? bannerHeight : 0), 
                zIndex: 100, 
                width: '100%',
                ...(globalHelper?.isMobileScreen() && { padding : '0 12px' }),
            }}
            >
                <MasterHeader />
            </Header>
        </>
    )
}

export default TopHeader;