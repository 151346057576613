import React from 'react';
import { useParams } from 'react-router-dom';
import PaymentLayout from './components/payment_layout';
import './payment.css';

const PaymentIndex = () => {
    const { running_number } = useParams();

    return (
        <>
            <div>
                <PaymentLayout running_number={running_number}/>
            </div>
        </>
    );
}

export default PaymentIndex;