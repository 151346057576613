import React, { useState } from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Badge, Button, Image, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import globalHelper from '../../../helpers/functions/global.helper';
import datePaymentService from '../../../services/payment/date_payment.service';

const PaymentDateResultFail = ({date_change}) => {
    const [state, setState] = useState({
        link_loading : false,
    });

    const goToPayment = async () => {
        setState(state => ({...state, link_loading : true}));
        const date_change_running_number = date_change?.running_number;
        const datePaymentLinkResponse = await datePaymentService.generateDatePaymentLink(date_change_running_number).catch(err =>{
            setState(state => ({...state, link_loading : false}));
        });

        setState(state => ({...state, link_loading : false}));
        window.location.href = datePaymentLinkResponse?.url;
    }
    
    return (
        <>
            <div className='mobile-padding-24' style={{ width:'100%'}}>
                <div style={{position:'relative'}}>
                    <EntranceMotion initialY={-100} duration={0.5}>
                        <Badge count={
                                <div>
                                    <EntranceMotion initialY={-50} duration={0.5} delay={0.2}>
                                        <Tag
                                        color={'red'} 
                                        style={{
                                            border:'none', 
                                            textAlign:'center', 
                                            borderRadius:'50%', 
                                            width:40, 
                                            height:40, 
                                            display:'flex', 
                                            justifyContent:'center',
                                            alignItems:'center',
                                        }}
                                        >
                                            <CloseOutlined style={{fontSize:20}} />
                                        </Tag>
                                </EntranceMotion>
                                </div>
                        }
                        offset={[-16, 105]}
                        >
                            <Image src='/cibanners/fail-ticket.png' style={{maxWidth: (globalHelper?.isMobileScreen() ? 120 : 140)}} preview={false}/>
                        </Badge>
                    </EntranceMotion>
                </div>
                
                <div style={{marginTop:12}}>
                    <EntranceMotion initialY={-100} duration={0.5} delay={0.1}>
                        <div>
                            <div>
                                <span className='ticket-label ticket-title h1' style={{fontWeight:'bold',}}>Oh no! Payment Unsuccessful</span>
                            </div>
                            <div>
                                <span style={{fontWeight:500, fontSize:18, color:'var(--secondary-text-color)'}}>We aren't able to proccess your payment. Please try again</span>
                            </div>
                        </div>

                    </EntranceMotion>
                </div>

                <div style={{marginTop:42}}>
                    <Button onClick={goToPayment} type='primary' size='large' style={{color:'var(--main-text-color)', fontWeight:500, minWidth:200}}>Try Again</Button>
                </div>
            </div>
        </>
    );
}

export default PaymentDateResultFail;