import React from 'react';

const SubResultItem = ({title, value}) => {
    return (
        <>
            <div>
                <div>
                    <span style={{color:'#595959', fontWeight:500}}>{title}</span>
                </div>
                <div>
                    <span>{value}</span>
                </div>
            </div>
        </>
    );
}

export default SubResultItem;