import { Button, Col, Image, Row } from 'antd';
import React from 'react';
import GreyContainer from '../../../helpers/components/grey_container';
import globalHelper from '../../../helpers/functions/global.helper';
import { Link } from 'react-router-dom';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const UploadFaceGuide = ({ link }) => {
    const is_mobile = globalHelper.isMobileScreen();

    const SelfieButtonComponent = ({width = 'auto'}) => {
        return (
            <>
                <div style={{marginTop:8}}>
                    <Link to={link}>
                        <Button type='primary' size={'large'} style={{color:'var(--main-text-color)', fontWeight:500, minWidth:width}}>Lets Get Started</Button>
                    </Link>
                </div>
            </>
        )
    }

    const steps = [{
        title : "Step 1",
        message : "Capture a nice selfie for each visitors and upload it to us.",
        image : "/cibanners/selfie-guide-step-1.png",
    },{
        title : "Step 2",
        message : "Get in fun with just a scan of your face at the entrance!",
        image : "/cibanners/selfie-guide-step-3.png"
    }]


    return (
        <>
            <Row gutter={[20, 8]} align={'top'}>
                <Col xs={24} md={12} span={12}>
                    <div>
                        <div>
                            <span style={{fontSize: (is_mobile ? 16 : 18), fontWeight:500, color:'var(--secondary-text-color)'}}>Before your visit, follow these simple steps for better entrance experiences.</span>
                        </div>
                        
                        {
                            !is_mobile &&
                            (
                                <>
                                    <div style={{paddingTop:8}}>
                                        <SelfieButtonComponent width={200} />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </Col>
                <Col xs={24} md={12} span={12}>
                    <div>
                        <Row gutter={[12]}>
                            {
                                steps?.map((step, index) => {
                                    const { title, message, image } = step ?? {};
                                    return (
                                        <Col xs={12} sm={8} md={12} span={12} key={index}>
                                            <EntranceMotion delay={index * 0.1} style={{height:'100%'}}>
                                                <GreyContainer padding={2} height={'100%'}>
                                                    <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
                                                        <div style={{padding:'8px 12px', color:'#4d4f5e', }}>
                                                            <div>
                                                                <span style={{ fontWeight:'500' }}>{title}</span>
                                                            </div>
                                                            <div>
                                                                <span>{message}</span>
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop:'auto',}}>
                                                            <Image src={image} preview={false} />
                                                        </div>
                                                    </div>
                                                </GreyContainer>
                                            </EntranceMotion>
                                        </Col>
                                    )
                                })
                            }
                            
                        </Row>
                    </div>
                </Col>
            </Row>

            {
                is_mobile &&
                (
                    <>
                        <div style={{textAlign:'center',}}>
                            <SelfieButtonComponent width={'100%'} />
                        </div>
                    </>
                )
            }

            
        </>
    );
}

export default UploadFaceGuide;