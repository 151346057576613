import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const OpenDateSummaryDateCalendar = () => {
    return (
        <>
            <div style={{ height : 'auto', width: 'auto', background:'#fff', borderRadius:6}}>
                <div style={{background:'var(--main-color)', height: 6, borderRadius:'6px 6px 0 0'}} />
                <div style={{
                    height:'100%', 
                    padding:'4px', 
                    textAlign:'center', 
                    border : '1px solid #cbd5e0', 
                    borderTop:'none', 
                    borderBottomLeftRadius:6, 
                    borderBottomRightRadius:6,
                }}>
                    <div style={{ width : 50, height : 50, }}>
                        <EntranceMotion style={{ height : '100%', width : '100%', }}>
                            <div style={{display : 'flex', alignItems : 'center', justifyContent : 'center', height : 'inherit', lineHeight : 1.2, }}>
                                <div style={{fontWeight:'normal',}}>
                                    <div>
                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500, fontSize : 14,}}>Open Date</span>
                                    </div>
                                </div>
                            </div>
                        </EntranceMotion>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OpenDateSummaryDateCalendar;