import React, { useEffect, useState, } from 'react';
import parkService from '../../../services/config/park.service';
import OpenDateTicketUserList from './open_date_ticket_user_list';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import OpenDateStepNoItem from './open_date_step_no_item';

const OpenDateParkApplicableTicketTypeItem = ({ park, ticket_type, }) => {
    const [state, setState] = useState({
        loading : false,
        park_applicable_ticket_users : [],
        park_applicable_add_ons : [],
        advance_day_discount : [],
        peak_day_discount : [],
    })

    const fetchParkApplicableTicketTypeItems = async () => {
        setState(state => ({...state, loading : true}));
        const park_applicable_ticket_type_id = (ticket_type?.ParkApplicableTicketType?.park_applicable_ticket_type_id);
        const parkApplicableTicketUserResponse = await parkService.getParkApplicableTicketTypeitems({
            park_applicable_ticket_type_id,
            purchase_type_code : 'OL',
        });

        const { park_applicable_ticket_users, park_applicable_add_ons, advance_day_discount, peak_day_discount } = parkApplicableTicketUserResponse ?? {};
        setState(state => 
        ({...state, 
            park_applicable_ticket_users : park_applicable_ticket_users, 
            park_applicable_add_ons : park_applicable_add_ons,
            advance_day_discount : advance_day_discount,
            peak_day_discount : peak_day_discount,
            loading : false,
        }));
    }

    useEffect(() => {
        if(ticket_type){
            fetchParkApplicableTicketTypeItems();
        }
    }, [ticket_type?.ticket_type_id])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div style={{ textAlign : 'start', marginTop : 42, }}>
                    {/* Ticket User List */}
                    <OpenDateStepNoItem no={3} text={'Tickets'} image={'/cibanners/step/balloon.png'} />
                    <OpenDateTicketUserList park_applicable_ticket_users={state?.park_applicable_ticket_users} />
                </div>

                <div>
                    {/* Add On */}
                </div>
            </Spin>
        </>
    );
}

export default OpenDateParkApplicableTicketTypeItem;