import { Button, notification } from 'antd';
import React, { useState} from 'react';
import { useSelector } from 'react-redux';
import dateService from '../../../services/ticketing/date.service';
import datePaymentService from '../../../services/payment/date_payment.service';

const DateCheckoutButton = ({ ticket_unique_no }) => {
    const dateRedux = useSelector(state => state?.date);
    const [state, setState] = useState({
        checkout_loading : false,
    })

    const dateCheckout = async () => {
        setState(state => ({...state, checkout_loading : true, }));
        const { visit_date, final_price, preference, } = dateRedux;
        const createDateChangeResponse = await dateService.createChangeDate(ticket_unique_no, { 
            visit_date : visit_date,
            final_price : final_price,
            preference_id : preference?.preference_id,
        })
        .then(async (dateResponse) => {
            const { save_date_change, errors } = dateResponse;
            const date_change_running_number = (save_date_change?.running_number);
            if(errors?.length > 0){
                notification.warning({
                    message : 'Oops!',
                    description : errors[0]?.message,
                })
            }else{
                //get payment link
                const paymentLinkResponse = await datePaymentService.generateDatePaymentLink(date_change_running_number).catch(err =>{
                    setState(state => ({...state, save_loading : false}));
                });

                window.location.href = paymentLinkResponse?.url;
            }
            setTimeout(() => {
                setState(state => ({...state, checkout_loading : false,}));
            }, 200);
        })
        .catch(err => {
            setState(state => ({...state, checkout_loading : false}));
        });
    }

    return (
        <>
            <Button
            loading={state?.checkout_loading}
            size='large'
            type='primary'
            style={{width:'100%', color : 'var(--main-text-color)', fontWeight:500,}}
            onClick={dateCheckout}
            >
                Check Out
            </Button>
        </>
    );
}

export default DateCheckoutButton;