import React, { useEffect, useState } from 'react';
import preferenceService from '../../../services/config/preference.service';
import { Card, Col, Row } from 'antd';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import StepNoItem from './step_no_item';
import { useDispatch, useSelector } from 'react-redux';
import { set_purchase_detail } from '../../../actions/purchase.action';
import globalHelper from '../../../helpers/functions/global.helper';
import { CheckOutlined } from '@ant-design/icons';
import { set_capacity, set_preference } from '../../../actions/ticket.action';
import parkService from '../../../services/config/park.service';

const PreferenceList = ({ visit_date, ticket_type, balloonRef, }) => {
    const purchaseRedux = useSelector(state => state.purchase);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : false,
        preference_groups : [],
    })

    const selectPreference = async (preference_id) => {
        dispatch(set_purchase_detail({ preference_id }));
    }

    const preferenceChange = async () => {
        // when preference id is selected, scroll to ticket user part
        if(purchaseRedux?.preference_id && balloonRef?.current){
            const headerHeight = document.querySelector('header')?.offsetHeight ?? 0;
            const topOffset = balloonRef.current.getBoundingClientRect().top + window.scrollY - (headerHeight + 40);
            window.scrollTo({ top : topOffset, behavior: 'smooth' });
        }

        const formatted_visit_date = (globalHelper.parseDate(visit_date));
        await checkCapacity(formatted_visit_date);
    }

    const checkCapacity = async (visit_date) => {
        const { park_id, preference_id, } = purchaseRedux;
        const capacityResponse = await parkService?.getCapacity({ visit_date, park_id, preference_id, });
        const { capacity, remaining_capacity, is_capacity, } = capacityResponse;
        dispatch(set_capacity(is_capacity, remaining_capacity));
        return { capacity, remaining_capacity, is_capacity, };
    }

    const fetchPreferences = async () => {
        setState(state => ({...state, loading : true, }));
        const { ParkApplicableTicketType, } = ticket_type;
        const preferenceResponse = await preferenceService?.getPreferences({
            visit_date,
            park_applicable_ticket_type_id : ParkApplicableTicketType?.park_applicable_ticket_type_id,
        });
        const { preference_groups, } = preferenceResponse;
        setState(state => ({...state, loading : false, preference_groups : preference_groups, }));
        // set to redux for summary usage
        dispatch(set_preference(preference_groups));
    }
    
    useEffect(() => {
        visit_date && fetchPreferences();
    }, [visit_date]);

    useEffect(() => {
        preferenceChange();
    }, [purchaseRedux?.preference_id]);

    return (
        <>
            {
                state?.preference_groups?.length > 0 &&
                (
                    <>
                        <div>
                            <EntranceMotion initialY={-100} duration={0.5} delay={-0.5}>
                                <div className='step-4' style={{marginTop:48}}>
                                    <StepNoItem no={3} text={'Visit Time'} image={'/cibanners/step/preference.png'} />
                                    <div style={{marginTop:12, padding : '0px 12px', }}>
                                        <Row gutter={[32, 32]}>
                                        {
                                            state?.preference_groups?.map((preference_group, index) => {
                                                const { preferences, name : preference_group_name, } = preference_group;
                                                return (
                                                    <Col xs={24} md={24} lg={12} key={index}>
                                                        <div>
                                                            <div>
                                                                <span style={{fontWeight:500, fontSize:16,}}>{preference_group_name}</span>
                                                            </div>
                                                            <div style={{marginTop: 12,}}>
                                                                <Row gutter={[8, 8]}>
                                                                {
                                                                    preferences?.map((preference, pindex) => {
                                                                        const { preference_id, name : preference_name, } = preference;
                                                                        const selected_preference = preference_id === purchaseRedux?.preference_id;
                                                                        return (
                                                                            <Col span={12} key={pindex}>
                                                                                <Card
                                                                                onClick={() => selectPreference(preference_id)}
                                                                                className='ticket-type-card'
                                                                                style={{ cursor : 'pointer', ...(selected_preference && ({ background : 'var(--lighten-main-color)', color : 'var(--main-color)', borderColor : 'var(--main-color)' }))}}
                                                                                bodyStyle={{ padding : 8, }}
                                                                                >
                                                                                    <div style={{display : 'flex', }}>
                                                                                        <div>
                                                                                            <span>{preference_name}</span>
                                                                                        </div>
                                                                                        {
                                                                                            selected_preference &&
                                                                                            (
                                                                                                <div style={{marginLeft:'auto'}}>
                                                                                                    <span><CheckOutlined /></span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        
                                                                                    </div>
                                                                                </Card>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                        </Row>
                                    </div>
                                </div>
                            </EntranceMotion>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default PreferenceList;