import { Col, Divider, List, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import PaymentBreakdown from './payment_breakdown';
import { useSelector } from 'react-redux';

const PaymentDetail = ({ ticket_users, add_ons, total_amount, promotion_code, actual_price }) => {
    const ticketRedux = useSelector(state => state.ticket);
    const currency = ticketRedux?.park?.currency?.symbol;

    const [state, setState] = useState({
        payment_tickets : [],
        payment_add_ons : [],
    });

    const initializePaymentDetail = () => {

        setState(state => ({...state, payment_tickets : ticket_users, payment_add_ons : add_ons}))
    }

    useEffect(() => {
        initializePaymentDetail();
    }, [])
    
    return (
        <>
            <div>
                
                <div>
                    {/* tickets */}
                    <div>
                        <span style={{fontWeight:500, fontSize:18, color:'#6f7287'}}>Tickets</span>
                    </div>
                    <List 
                    dataSource={state?.payment_tickets}
                    renderItem={(item, index) => {
                        const { ticket_user_type, count: quantity, price  } = item ?? {};
                        const payment_price = price * quantity;
                        return (
                            <List.Item key={index} style={{paddingLeft:0, paddingRight:0}}>
                                <EntranceMotion initialY={-50} duration={1} delay={index * 0.1} style={{width : '100%'}}>
                                    <div style={{display:'flex',}}>
                                        <div>
                                            <Space>
                                                <div style={{minWidth:80}}>
                                                    <span className='ticket-label' style={{fontWeight:500, }}>
                                                        {`${ticket_user_type?.name}`}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className='ticket-label' style={{fontWeight:500, }}>
                                                        x {quantity}
                                                    </span>
                                                </div>
                                            </Space>
                                            <div style={{color:'#6f7287'}}>
                                                {`${currency}${price?.toFixed(2)}`}
                                            </div>
                                        </div>

                                        <div style={{marginLeft:'auto'}}>
                                            <span className='ticket-label' style={{ fontSize:16 }}>
                                                {`${currency}${payment_price?.toFixed(2)}`}
                                            </span>
                                        </div>
                                    </div>
                                </EntranceMotion>
                            </List.Item>
                        )
                    }}
                    />
                </div>



                {/* add ons */}
                {
                    state?.payment_add_ons?.length > 0 &&
                    (
                        <>
                            <Divider />
                            <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:18, color:'#6f7287'}}>Add Ons</span>
                                </div>
                                <List
                                dataSource={state?.payment_add_ons}
                                renderItem={(item, index) => {
                                    const { name, description, TicketAddOn } = item;
                                    const payment_price = TicketAddOn?.price * TicketAddOn?.quantity;
                                    return (
                                        <List.Item key={index} style={{paddingLeft:0, paddingRight:0}}>
                                            <EntranceMotion initialY={-50} duration={1} delay={index * 0.1} style={{width : '100%'}}>
                                                <div style={{display:'flex',}}>
                                                    <div>
                                                        <Space>
                                                            <div style={{minWidth:80}}>
                                                                <span className='ticket-label' style={{fontWeight:500, }}>
                                                                    {`${name}`}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className='ticket-label' style={{fontWeight:500, }}>
                                                                    x {TicketAddOn?.quantity}
                                                                </span>
                                                            </div>
                                                        </Space>
                                                        <div style={{color:'#6f7287'}}>
                                                            {description}
                                                        </div>
                                                        <div style={{color:'#6f7287'}}>
                                                            {`${currency}${TicketAddOn?.price?.toFixed(2)}`}
                                                        </div>
                                                    </div>

                                                    <div style={{marginLeft:'auto'}}>
                                                        <span className='ticket-label' style={{ fontSize:16 }}>
                                                            {`${currency}${payment_price?.toFixed(2)}`}
                                                        </span>
                                                    </div>
                                                </div>
                                            </EntranceMotion>
                                        </List.Item>
                                    )
                                }}
                                />
                            </div>
                        </>
                    )
                }
                

                <Divider />
                <div>
                    <PaymentBreakdown
                    total_amount={(parseFloat(total_amount)?.toFixed(2))}
                    promotion_code={promotion_code}
                    actual_price={actual_price}
                    />
                </div>
            </div>
        </>
    );
}

export default PaymentDetail;