import { Card, Divider, Space, Tag } from 'antd';
import React from 'react';
import ResultItem from '../../ticket/components/result_item';
import SubResultItem from '../../ticket/components/sub_result_item';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const TicketSummary = ({ticket}) => {
    const ticket_count = (ticket?.ticket_users?.reduce((a, b) => a + b.count, 0));
    return (
        <>
            <EntranceMotion>
                <Card
                // className='card-shadow'
                styles={{ body : { padding : 0, } }}
                style={{border:'1.5px solid #cbd5e0'}}
                >
                    <div style={{textAlign:'start'}}>
                        <div style={{padding:24,}}>
                            <ResultItem icon={'ticket.svg'} title="Ticket Summary" value={
                                <>
                                    <div>
                                        <Space direction='vertical' style={{width:'100%'}} size={12}>
                                            <SubResultItem title="Transaction Number" value={`${ticket?.unique_no}${ticket?.salt ?? ''}`} />
                                            <SubResultItem title="Status" value={
                                                (
                                                    <>
                                                        {
                                                        ticket?.payment_status?.is_payment_complete ?
                                                        (
                                                            <>
                                                                <Tag color='green' style={{border:'none'}}>
                                                                    {ticket?.payment_status?.name}
                                                                </Tag>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <Tag color='oranage' style={{border:'none'}}>
                                                                    {ticket?.payment_status?.name}
                                                                </Tag>
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                )
                                            } />
                                            <SubResultItem title="Ticket Type" value={ticket?.ticket_type?.name} />
                                            <SubResultItem title="Ticket Count" value={ticket_count} />
                                            {
                                                ticket?.preference && (
                                                    <>
                                                        <SubResultItem title="Visit Time" value={(
                                                            <>
                                                                {`${ticket?.preference?.preference_group ? (`${ticket?.preference?.preference_group?.name} - `) : ''} ${(ticket?.preference?.name)}`}
                                                            </>
                                                        )} />
                                                    </>
                                                )
                                            }
                                            
                                        </Space>
                                    </div>
                                </>
                            } />
                        </div>
                        <Divider style={{margin:0,}}/>
                        <div style={{padding:24,}}>
                            <SubResultItem title="Current Visit Date" value={(ticket?.is_open_date ? "Open Date" : ticket?.visit_date)} />
                        </div>
                    </div>
                </Card>
            </EntranceMotion>
        </>
    );
}

export default TicketSummary;