import React from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Link } from 'react-router-dom';

const TicketSearchBottomLayout = ({ onClick = () => {}, input, }) => {

    return (
        <>
            {
                globalHelper.isMobileScreen() &&
                (
                    <>
                        <div
                        onClick={onClick}
                        className={`footer-checkout-button ${!input ? 'disabled' : ''}`}
                        >
                            <EntranceMotion initialY={100} duration={1}>
                                <div style={{padding:'.75rem', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <div>
                                        <span style={{fontSize:18, fontWeight:500, color:'var(--main-text-color)'}}>Search Ticket</span>
                                    </div>
                                </div>
                            </EntranceMotion>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default TicketSearchBottomLayout;