import { Calendar, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import PurchaseTicketDatePicker from '../../purchase/components/purchase_ticket_date_picker';
import parkService from '../../../services/config/park.service';
import { useDispatch, useSelector } from 'react-redux';
import { set_visit_date } from '../../../actions/date.action';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const MobileDateCalendar = ({park,}) => {
    const dispatch = useDispatch();
    const dateRedux = useSelector(state => state.date);
    const [state, setState] = useState({
        loading : true,
        park : null,
    });
    const changeDate = (ds, date) => {
        const new_date = dayjs(ds).format('YYYY-MM-DD');
        dispatch(set_visit_date(new_date));
    }

    const fetchPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService.getPark({
            park_code : park?.park_code
        });

        setState(state => ({...state, loading : false, park : parkResponse?.parks}));
    }

    useEffect(() => {
        fetchPark();
    }, [])
    
    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div style={{minHeight:200,}}>
                {
                    state?.park &&
                    (
                        <>
                            <EntranceMotion>
                                <PurchaseTicketDatePicker park={state?.park} is_screen changeDate={changeDate}
                                {...dateRedux?.visit_date && ({ defaultValue : dayjs(dateRedux.visit_date) }) } />
                            </EntranceMotion>
                        </>
                    )
                }
                </div>
            </Spin>
        </>
    );
}

export default MobileDateCalendar;