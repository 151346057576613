export const set_purchase_detail = ({visit_date, ticket_type_id, ticket_users, email, park_id, name, add_ons, promotion_code, preference_id, liability_waiver_checked, final_price, open_date, is_null,}) => {
    return {
        type : 'SET_PURCHASE_DETAIL',
        visit_date,
        ticket_type_id,
        ticket_users,
        add_ons,
        email,
        park_id,
        name,
        promotion_code,
        preference_id,
        liability_waiver_checked,

        //price
        final_price,

        // open date
        open_date,

        //check whether remove the item
        is_null,
    };
}

export const reset_purchase_detail = (park_id,) => {
    return {
        type : 'RESET_PURCHASE_DETAIL',
        park_id
    }
}