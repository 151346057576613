import { LinkOutlined, LoadingOutlined, ScanOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, QRCode, Space, Spin, Tag } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import { RWebShare } from 'react-web-share';
import IconWrapper from '../../../helpers/components/icon_wrapper';
import { Link } from 'react-router-dom';
import globalHelper from '../../../helpers/functions/global.helper';
import ShareTicketUser from './share_ticket_user';
import DynamicSvg from '../../../helpers/components/dynamic_svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const style = {
    overflow : {
        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
    }
}

const TicketUserCard = ({ticket_user, ticket,}) => {
    const cardRef = useRef();
    const [state, setState] = useState({
        text_width : null,
        loading : false,
    })

    const setLoading = (loading) => {
        setState(state => ({...state, loading : loading,}));
    }

    //set text width
    useEffect(() => {
        if(cardRef?.current){
            const text_width = (cardRef?.current?.clientWidth * (globalHelper.isMobileScreen() ? 0.5 : 0.6));
            setState(state => ({...state, text_width : text_width}))
        }
    }, [cardRef]);
    
    return (
        <>
            <div ref={cardRef} className="ticket2 ticket-card" style={{height:'100%',}}>
                <div>
                    <div>
                        <Space style={{justifyContent:'start', width:'100%', alignItems:'start'}}>
                            <div>
                                {
                                    ticket_user?.image_url ?
                                    (
                                        <>
                                            <div style={{border:'1px solid var(--main-color)', borderRadius:6,}}>
                                                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                                                    <Avatar shape='square' size={60} src={
                                                        <LazyLoadImage beforeLoad={() => setLoading(true)} afterLoad={() => setLoading(false)} loading={state?.loading} src={ticket_user?.image_url} effect='blur' style={{width : 60, height : 60, objectFit:'cover',}} />
                                                    } />
                                                </Spin>
                                                
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Avatar style={{padding:4,}} shape='square' size={60} src={
                                                <DynamicSvg image={`/cicons/image.svg`} size={50} />
                                            } />
                                        </>
                                    )
                                }
                                
                            </div>
                        </Space>
                    </div>

                    <div style={{marginTop:8,}}>
                        <div style={{textAlign:'left', fontSize:14,}}>
                            <div>
                                <div>
                                    <span style={{ color:'var(--secondary-text-color)', fontSize:12}}>Ticket No</span>
                                </div>
                                <div style={{...style?.overflow, fontSize:14, }}>
                                    <span>{ticket_user?.ticket_user_no}</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span style={{ color:'var(--secondary-text-color)', fontSize:12}}>Ticket Type</span>
                                </div>
                                <div style={{ ...style?.overflow, fontSize:14,}}>
                                    <span>{`${ticket_user?.ticket_user_type?.name} 
                                    ${ticket_user?.ticket_user_type?.min_age ? (
                                        `(Age: 
                                            ${ticket_user?.ticket_user_type?.max_age ? 
                                                `${ticket_user?.ticket_user_type?.min_age} - ${ticket_user?.ticket_user_type?.max_age}` :
                                                `${ticket_user?.ticket_user_type?.min_age} - 100+`
                                        }
                                        )`
                                    ): ""}
                                    `}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{marginTop:24,}}>
                    {
                        ticket_user?.ticket_user_type?.is_config_face ?
                        (
                            <>
                                <Space direction='vertical' size={8}>
                                    <div>
                                        <div>
                                            <Link to={`/ticket/user/${ticket_user?.ticket_user_no}?ticket=${ticket?.token}`}>  
                                                <Button type="primary" icon={<ScanOutlined />} style={{color:'var(--main-text-color)', width:'100%'}}>Register Face</Button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <ShareTicketUser ticket_user_no={ticket_user?.ticket_user_no} park={ticket?.park}>
                                            <Button style={{ width:'100%'}} icon={<ShareAltOutlined style={{color:'#5F78A4',}} />}>
                                                <span style={{color:'#5F78A4', }}>Share</span>
                                            </Button>
                                        </ShareTicketUser>
                                    </div>
                                </Space>
                            </>
                        )
                        :
                        (
                            <>
                                <div style={{ background:'var(--secondary-button-color)', padding: 8, borderRadius: 8, }}>
                                    <span style={{ color : 'var(--secondary-text-color)', fontSize:12, }}>Selfie not required</span>
                                </div>
                            </>
                        )
                    }
                    
                </div>
            </div>
        </>
    );
}

export default TicketUserCard;