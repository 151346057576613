import { Col, Row, Skeleton, Space } from 'antd';
import React from 'react';

const TicketTypeLoading = () => {
    return (
        <>
            <div style={{textAlign : 'start',}}>
                <Space>
                    <Skeleton.Avatar shape='square' />
                    <Skeleton.Input style={{width : '100%'}} />
                </Space>
            </div>
            <div style={{ marginTop : 24, }}>
                <Row gutter={[12, 12]} justify="start">
                    {
                        [...Array(4)]?.map((i, index) => {
                            return (
                                <Col xs={12} sm={6} md={8} lg={6} span={6} key={index} style={{width : '100%'}}>
                                    <Skeleton.Input block active style={{height : 220, width : '100%',}} />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </>
    );
}

export default TicketTypeLoading;