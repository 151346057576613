import React, { useState } from 'react';
import StepNoItem from './step_no_item';
import { useEffect } from 'react';
import parkService from '../../../services/config/park.service';
import { Button, Image, InputNumber, List, Popover, Space } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { useDispatch, useSelector } from 'react-redux';
import { set_purchase_detail } from '../../../actions/purchase.action';
import globalHelper from '../../../helpers/functions/global.helper';
import GreyContainer from '../../../helpers/components/grey_container';
import { set_is_add_on } from '../../../actions/ticket.action';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AddOnImage from './add_on_image';
import numberHelper from '../../../helpers/functions/number.helper';

const PurchaseAddOn = ({ visit_date, ticket_type }) => {
    const purchaseRedux = useSelector(state => state.purchase);
    const ticketRedux = useSelector(state => state.ticket);
    const currency = ticketRedux?.park?.currency?.symbol;
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : false,
        add_ons : [],
    })

    const fetchAddOn = async () => {
        setState(state => ({...state, loading : true}));
        const { park_applicable_ticket_type_id, } = (ticket_type?.ParkApplicableTicketType);

        // check is special link
        const { special_link } = ticketRedux;
        let get_visit_date = special_link ? null : globalHelper?.parseDate(visit_date);
        const addOnsResponse = await parkService.getAddOn({
            park_applicable_ticket_type_id, 
            visit_date : get_visit_date,
            slot_visit_date : globalHelper?.parseDate(visit_date),
        });

        const { add_ons } = addOnsResponse;
        setState(state => ({...state, loading : false, add_ons : add_ons}));
        if(add_ons?.length > 0){
            // set is add on to redux
            dispatch(set_is_add_on(true,));
        }
        
    }

    const addQuantity = (add_on_id, quantity) => {
        let add_ons = purchaseRedux?.add_ons;
        //check currently have this ticket user or not
        let existing_add_on = add_ons?.find(x => x.add_on_id === add_on_id);
        if(existing_add_on){
            // const calculated_quantity = existing_add_on?.quantity + plus;
            const calculated_quantity = quantity;
            existing_add_on.quantity = calculated_quantity;
            let changed_quantity = add_ons;
            if(calculated_quantity > 0){
                changed_quantity = add_ons?.map((x) => {
                    return (x?.add_on_id === add_on_id) ? existing_add_on : x;
                })
            }else{
                changed_quantity = add_ons?.filter((x) => {
                    return (x?.add_on_id != add_on_id);
                })
            }
            //add quantity
            add_ons = changed_quantity;
        }else{
            //add a new object
            if(quantity > 0){
                const add_on = state?.add_ons?.find(x => x.add_on_id === add_on_id);
                const { add_on : get_add_on, price, add_on_setup_id, } = add_on;
                add_ons.push({
                    name : get_add_on?.name,
                    price : price,
                    add_on_id : add_on_id,
                    quantity : quantity,
                    add_on_setup_id : add_on_setup_id,
                })
            }
        }

        dispatch(set_purchase_detail({add_ons}));
    }

    useEffect(() => {
        fetchAddOn();
    }, [visit_date]); //normal got ticket type, but it will run twice, because parents component has already runned


    return (
        <>
            {
                (visit_date && state?.add_ons?.length > 0) &&
                (
                    <>
                        <EntranceMotion initialY={-100} duration={0.5} delay={0.2}>
                            <div className='step-4' style={{marginTop:48}}>
                                <StepNoItem no={4} text={'Add Ons'} image={'/cibanners/step/add-on.png'} emoji={<>🥪</>} />
                                <div style={{marginTop:12,}}>
                                    <List
                                    size='small'
                                    dataSource={state?.add_ons}
                                    renderItem={(item, index) => {
                                        const { name, add_on, price, advance_day, add_on_id, available_slots } = item ?? {};
                                        const quantity = purchaseRedux?.add_ons?.find(x => x.add_on_id === add_on_id)?.quantity;
                                        let is_sold_out = false;
                                        const is_available = (available_slots == undefined || available_slots > 0);
                                        if(available_slots != null){
                                            const remaining_quantity = available_slots - (quantity ?? 0);
                                            is_sold_out = remaining_quantity <= 0;
                                        }
                                        
                                        return (
                                            <List.Item>
                                                <EntranceMotion initialY={-50} duration={1} style={{width : '100%'}}>
                                                    <div style={{width:'100%'}}>
                                                        <div style={{display:'flex', flexWrap:'wrap', gap:24}}>
                                                            <div>
                                                                <Space align='start'>
                                                                    <div>
                                                                        {
                                                                            add_on?.image &&
                                                                            (
                                                                                <>
                                                                                    <GreyContainer padding={0}>
                                                                                        <AddOnImage image={add_on?.image} />
                                                                                    </GreyContainer>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span className='ticket-label' style={{fontWeight:500, fontSize:14}}>
                                                                                {`${add_on?.name}`}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span style={{color:'#6f7287', fontSize:12,}}>
                                                                                {add_on.description}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Space>
                                                            </div>
                                                            
                                                            <div style={{marginLeft:'auto'}}>
                                                                <div>
                                                                    <Space size={20}>
                                                                        <div>
                                                                            <span className='ticket-label' style={{fontWeight:500, fontSize:14}}>
                                                                                {`${currency}${numberHelper?.commaNumber(price,)}`}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                            disabled={(!quantity || quantity <= 0)}
                                                                            onClick={() => addQuantity(add_on_id, (isNaN(quantity) ? 0 : parseInt(quantity) - 1))}
                                                                            type='ghost'
                                                                            style={{background:'#F8F7FA', padding:'4px 10px', opacity : ((!quantity || quantity <= 0) ? '0.5' : '1' )}}
                                                                            >
                                                                                <MinusOutlined />
                                                                            </Button>
                                                                        </div>
                                                                        <div style={{opacity : (is_available ? 1 : 0.5)}}>
                                                                            {/* <span style={{fontSize:16, opacity : (is_available > 0 ? 1 : 0.5)}}>
                                                                                {quantity ?? 0}
                                                                            </span> */}
                                                                            <Popover
                                                                            content={
                                                                                <>
                                                                                    <div style={{padding:8,}}>
                                                                                        <InputNumber
                                                                                        precision={0}
                                                                                        type='number'
                                                                                        placeholder='Enter ticket quantity'
                                                                                        value={quantity}
                                                                                        onChange={(q) => addQuantity(add_on_id, q)} 
                                                                                        style={{width:'100%'}}
                                                                                        max={available_slots} 
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            trigger={ is_available ? ['click'] : []}
                                                                            placement='bottom'
                                                                            overlayInnerStyle={{padding : 0,}}
                                                                            >
                                                                                <Button>{quantity ?? 0}</Button>
                                                                            </Popover>
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                            disabled={is_sold_out}
                                                                            onClick={() => addQuantity(add_on_id, ((isNaN(quantity) ? 0 : parseInt(quantity)) + 1))}
                                                                            type='ghost' 
                                                                            style={{background:'#F8F7FA', padding:'4px 10px',  opacity : ((is_sold_out) ? '0.5' : '1' )}}
                                                                            >
                                                                                <PlusOutlined />
                                                                            </Button>
                                                                        </div>
                                                                    </Space>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </EntranceMotion>
                                            </List.Item>
                                        )
                                    }}
                                    />
                                </div>
                            </div>
                        </EntranceMotion>
                    </>
                )
            }
            
        </>
    );
}

export default PurchaseAddOn;