import React from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import { Card, Col, Image, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { set_purchase_detail } from '../../../actions/purchase.action';
import OpenDateStepNoItem from './open_date_step_no_item';

const OpenDateTicketType = ({ park, }) => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state?.purchase);

    const changeDetail = (key, value) => {
        dispatch(set_purchase_detail({
            [key] : value
        }))
    }

    const selectTicketType = (ticket_type_id) => {
        //when change ticket, reset the user list and add on list
        dispatch(set_purchase_detail({ticket_users : [], add_ons : []}));
        changeDetail('ticket_type_id', ticket_type_id);
    }

    return (
        <>
            <div>
                <div id="step_1" className='step-1' style={{ textAlign : 'start', }}>
                    <OpenDateStepNoItem no={1} text={'Ticket Type'} image={'cibanners/step/ticket-type.png'}/>
                    <div style={{ marginTop : 12, }}>
                        <Row gutter={[12, 12]} justify="start">
                            {
                                park?.ticket_types?.sort((a, b) => a?.ParkApplicableTicketType?.sequence - b?.ParkApplicableTicketType?.sequence)?.map((ticket_type, index) => {
                                    const selected_ticket_type = ticket_type?.ticket_type_id === purchaseRedux?.ticket_type_id;
                                    const { image } = ticket_type?.ParkApplicableTicketType;
                                    return (
                                        <Col xs={12} sm={6} md={8} lg={6} span={6} key={index}>
                                            <EntranceMotion initialY={-50} duration={1} delay={index * 0.1} style={{height:'100%'}}>
                                                <Card
                                                className={`ticket-type-card ${selected_ticket_type ? 'selected' : ''}`}
                                                onClick={() => selectTicketType(ticket_type?.ticket_type_id)}
                                                style={{height:'100%', borderWidth:1.5, cursor : 'pointer', }}
                                                styles={{ body : { padding : 12, paddingTop : 12, }}}
                                                cover={
                                                    <div style={{padding : 8, paddingBottom : 0,}}>
                                                        <div style={{ height:'100%', width:'auto', textAlign:'center',}}>
                                                            <div style={{ background:'var(--secondary-button-color)', maxWidth:'100%'}}>
                                                                <Image preview={false} src={image} style={{objectFit:'contain', borderRadius:8,}}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                >
                                                    <div style={{textAlign:'center',}}>
                                                        <div>
                                                            <span className='header-font' style={{fontWeight:'500', color : '#434343'}}>{ticket_type?.name}</span>
                                                        </div>
                                                        <div>
                                                            <span style={{color:'#6f7287', fontSize:12,}}>{ticket_type?.ParkApplicableTicketType?.descriptions}</span>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </EntranceMotion>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OpenDateTicketType;