import { EnvironmentFilled, InfoCircleFilled } from '@ant-design/icons';
import { Alert, Space } from 'antd';
import React, { useMemo } from 'react';
import ReactQuill from 'react-quill-v2.0';

const Address = ({ address, description, }) => {
    const is_delta_empty = useMemo(() => {
        const delta = description ? JSON.parse(description) : null;
        return (delta?.ops?.length === 1 && delta?.ops?.[0]?.insert === '\n');
    }, [description])

    const description_delta = useMemo(() => {
        return description ? JSON.parse(description) : null;
    }, [description])
    return (
        <>
            <div style={{ padding : '0 24px'}}>
                <div style={{textAlign:'start', background:'var(--secondary-button-color)', borderRadius:8, marginTop:12,}}>
                    <div style={{padding: 12,}}>
                        <Space direction='vertical' style={{ width : '100%', }}>
                            <div>
                                <Space align='start'>
                                    <EnvironmentFilled style={{ color : 'var(--secondary-text-color)', }} />
                                    <div>
                                        <span
                                        className='ticket-label ticket-title h1'
                                        style={{ fontSize:12, color:'var(--secondary-text-color)', whiteSpace : 'wrap',}}
                                        >
                                            {address}
                                        </span>
                                    </div>
                                </Space>
                            </div>
                            {
                                ((description) && (!is_delta_empty)) && (
                                    <>
                                        <div>
                                            <Space align='start'>
                                                <InfoCircleFilled style={{ color : 'var(--secondary-text-color)', }} />
                                                <div>
                                                    <ReactQuill
                                                    readOnly
                                                    className='ticket-label ticket-title h1 description-quill'
                                                    theme={null}
                                                    value={description_delta}
                                                    style={{ fontSize:12, color:'var(--secondary-text-color)', whiteSpace : 'wrap', margin : 0, }}
                                                    />
                                                </div>
                                            </Space>
                                        </div>
                                    </>
                                )
                            }
                        </Space>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Address;