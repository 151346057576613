import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const OpenDatePicker = ({ expired_date, date, park, form, park_applicable_ticket_type, error, resetErrors = () => {}, }) => {
    const disabledDate = (current) => {
        const custom_format = "YYYY-MM-DD";
        let custom_date = moment().format(custom_format);
        // let custom_expired_date = moment(expired_date)?.add(1, 'days').format(custom_format);
        let custom_expired_date = moment(expired_date)?.format(custom_format);
        const previous_date = current < moment(custom_date, custom_format);
        const future_expired_date = current > (moment(custom_expired_date, custom_format));

        const { date_range, advance_day, } = park_applicable_ticket_type ?? {};
        //get all maintenance date
        const maintenances = park?.maintenance_frequencies;
        let date_range_arr = [];
        let frequence_disabled = false;
        // let date_range_disabled = false;
        if(maintenances){
            for(const maintenance of maintenances){
                const start_date = maintenance?.ParkMaintenance?.start_date;
                const end_date = maintenance?.ParkMaintenance?.end_date;
                const maintenance_frequency = (maintenance?.code === 'DL') ? // days calculate differently
                (current.format(maintenance?.format)) :
                (moment(start_date)?.format(maintenance?.format));
    
                if(end_date){
                    if(
                        (current.format(custom_format) >= moment(start_date).format(custom_format)) &&
                        (current.format(custom_format) <= moment(end_date).format(custom_format))
                    ){
                        frequence_disabled = (current.format(maintenance?.format) == maintenance_frequency)
                    }
                    
                }else{
                    if(current.format(custom_format) >= moment(start_date).format(custom_format)){
                        frequence_disabled = (current.format(maintenance?.format) == maintenance_frequency)
                    }
                }

                // if date range, then previous date also need to disable
                if(date_range){
                    // if current add date range in between frequency
                    // then disable it
                    let date_range_disable_arr = [];
                    for(let i = 1; i <= date_range; i++){
                        const current_add_date_range = (current)?.add(i, 'days').format(maintenance?.format);
                        const is_within_maintainence_date = current_add_date_range == maintenance_frequency;
                        date_range_disable_arr.push(is_within_maintainence_date);
                    }
                    const is_disabled = (date_range_disable_arr?.find(x => x == true));
                    date_range_arr?.push(is_disabled);
                }
            }
        }

        const date_range_disabled = date_range_arr?.find(x => x === true) ?? false;

        let is_advance_day = false; // ignore the advance day check as they might pick day when they visit
        // if(advance_day){
        //     // disabled day before advance day
        //     const disable_end = ((moment(custom_date))?.add(advance_day, 'days'))?.format(custom_format);
        //     const today = current.format(custom_format);
        //     const is_between_disable = moment(today)?.isBetween(custom_date, disable_end, undefined, '[)');
        //     is_advance_day = is_between_disable;
        // }

        const disabled = date_range_disabled || frequence_disabled || is_advance_day || previous_date || future_expired_date;
        return disabled;
    }

    const initialize = async () => {
        onDateChange(date);
    }

    const onDateChange = (ds) => {
        form.setFieldsValue({visit_date : ds});

        // remove the error when date is selected
        resetErrors?.();
    }

    useEffect(() => {
        initialize();
    }, [])

    return (
        <>
            <DatePicker
            inputReadOnly
            disabledDate={disabledDate}
            onChange={(v, ds) => onDateChange(ds)}
            value={form.getFieldValue('visit_date') && dayjs(form.getFieldValue('visit_date'), 'YYYY/MM/DD')}
            className='ticket_user-form-item'
            popupClassName='purchase-visit-date-dropdown'
            style={error ? ({ background  : '#ffeded' }) : ({border:'2px solid #cbd5e0', borderRadius: 8})}
            suffixIcon={false}
            allowClear={false}
            showTime={false}
            {...error && ({ status : 'error', })}
            />
        </>
    );
}

export default OpenDatePicker;