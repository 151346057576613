import React, { useState, useEffect } from 'react';
import ticketService from '../../../services/ticketing/ticket.service';
import ContainerComponent from '../../../helpers/components/container.component';
import PaymentResultSuccess from './payment_result_success';
import { LoadingOutlined } from '@ant-design/icons';
import PaymentResultFail from './payment_result_fail';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';

const PaymentLayout = ({ running_number }) => {
    const [state, setState] = useState({
        loading : true,
        ticket : null,
    })

    const fetchTicket = async () => {
        setState(state => ({...state, loading : true}));
        const fetchTicketResponse = await ticketService.getTicket({
            running_number,
        });

        const ticket = fetchTicketResponse?.tickets[0];
        setTimeout(() => {
            setState(state => ({...state, loading : false, ticket : ticket}));

            // only clear storage when the payment is success
            if(ticket?.payment_status?.is_payment_complete){
                //clear localstorage
                localStorage.removeItem("purchase_item");
            }
            
        }, 500);
        
    }

    useEffect(() => {
        fetchTicket();
    }, [])

    return (
        <>
            <div style={{background:'#fff'}}>
                <ContainerComponent span={20} bottom={20} top={20}>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        {
                            state?.loading ? 
                            (
                                <>
                                    <div style={{padding : 24}}>
                                        <LoadingOutlined style={{fontSize:20}} />
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    {
                                        state?.ticket ?
                                        (
                                            <>
                                                {
                                                    state?.ticket?.payment_status?.is_payment_complete ?
                                                    (
                                                        <>
                                                            <PaymentResultSuccess ticket={state?.ticket}/>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <PaymentResultFail ticket={state?.ticket} />
                                                        </>
                                                    )
                                                }
                                                
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <SimpleNotFoundComponent
                                                // redirect_url={state?.park?.base_url}
                                                // redirect_name={`Continue without special link`}
                                                title={`Transaction Not Found`}
                                                description={`Transaction ${running_number} is not found in the records`}
                                                />
                                            </>
                                        )
                                    }
                                    
                                </>
                            )
                        }
                        
                    </div>
                </ContainerComponent>
            </div>
        </>
    );
}

export default PaymentLayout;